import React, { useRef, useState, useEffect } from "react";
import {
  LoadScript,
  Autocomplete,
} from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { handleGeocoder } from "../utils/geocoder";
import MapDelivery from "./Maps/MapDelivery";
import Loader from "../components/UI/Loader/Loader";

const coordinatesForCity = [{ lat: 44.7866, lng: 20.4489 }, { lat:  45.2671, lng: 19.8335 }];

const Beograd = {
  north: 44.897, // верхняя граница
  south: 44.723, // нижняя граница
  east: 20.551, // восточная граница
  west: 20.255, // западная граница
};

const NoviSad = {
  north: 45.316, // верхняя граница
  south: 45.204, // нижняя граница
  east: 19.916, // восточная граница
  west: 19.778, // западная граница
};

//documentation https://web.archive.org/web/20230701010714mp_/https://react-google-maps-api-docs.netlify.app/#section-introduction

const libraries = ["places"];
// хук для автозаполнения карт
function AddressAutocomplete({
  updateAddress,
  inputClassName,
  values,
  handleChange,
  addresses,
  handleInput,
  error,
  selectedCity
}) {
  const { t } = useTranslation();
  const [location, setLocation] = useState({ lat: 44.7866, lng: 20.4489 });
  const [options, setOptions] = useState({
    componentRestrictions: { country: "rs" },
    bounds: selectedCity === "Beograd" ?  Beograd : selectedCity === "NoviSad" ? NoviSad : undefined,
    strictBounds: true,
    types: ["address"],
  });

  const autoCompleteRef = useRef(null);

  const onPlaceChanged = () => {
    if (autoCompleteRef.current) {
      const newPlace = autoCompleteRef.current.getPlace();
      updateAddress(newPlace);
      setLocation({
        lat: newPlace.geometry.location.lat(),
        lng: newPlace.geometry.location.lng(),
      });
    }
  };

  useEffect(() => {
    if (values.coordinates) {
      const [lat, lng] = values.coordinates.split(", ");
      setLocation({ lat: parseFloat(lat), lng: parseFloat(lng) });
    }
  }, [values.coordinates]);

  const onDtagEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setLocation({ lat: lat, lng: lng });
    handleGeocoder({ lat: lat, lng: lng }).then((latlngResponse) => {
      handleChange({
        target: { name: "recipient_address", value: latlngResponse[0] },
      });
      handleChange({
        target: { name: "coordinates", value: `${lat}, ${lng}` },
      });
    });
  };

  // const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    handleChange({ target: { name: "recipient_address", value: value } });
  };

  // Создаем границы для Белграда


  // Объект опций для компонента Autocomplete
  // const options = {
  //   // Ограничение поиска местоположениями в Сербии с помощью странового кода 'rs'
  //   componentRestrictions: { country: "rs" },
  //   // Задаем границы для поиска
  //   bounds: selectedCity === "Beograd" ?  belgradeBounds : selectedCity === "NoviSad" ? noviSadBounds : undefined,
  //   // Заставляем Autocomplete строго придерживаться предоставленных границ
  //   strictBounds: true,
  //   types: ["address"],
  // };

  useEffect(() => {
    if (selectedCity && selectedCity.city === "Beograd") {
      setLocation(coordinatesForCity[0]);
      setOptions((prev) => ({...prev, bounds: Beograd}));
    } else if (selectedCity && selectedCity.city === "NoviSad") {
      setLocation(coordinatesForCity[1]);
      setOptions((prev) => ({...prev, bounds: NoviSad}));
    }
  }, [selectedCity]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      libraries={libraries}
      loadingElement={<Loader text={t("addresses.loading", "Загружаем адреса")}/>}
    >
      
      <Autocomplete
        onLoad={(autoC) => (autoCompleteRef.current = autoC)}
        onPlaceChanged={onPlaceChanged}
        options={options}
      >
        <input
          id="recipient_address"
          name="recipient_address"
          className={`delivery__input ${inputClassName} ${
            !values.recipient_address && "delivery__input--red"
          }`}
          value={values.recipient_address || ""}
          onChange={handleInputChange}
          onInput={handleInput}
          type="text"
          placeholder={t("addresses.full_address", "Введите Ваш полный адрес")}
          minLength="5"
          maxLength="400"
          required
        />
      </Autocomplete>
      <span className={`${error ? "login__error" : "login__error_hidden"}`}>
        {error && (
          <p>
            {" "}
            {t(
              "addresses.enter_your_full_address",
              "Введите улицу и номер дома"
            )}{" "}
          </p>
        )}
      </span>

      <div className="map_helptext">
        {t(
          "addresses.helptext",
          "Перетащите указатель в нужное место, если точка доставки определяется неправильно"
        )}
      </div>
      <MapDelivery location={location}  onDtagEnd={onDtagEnd}/>
    </LoadScript>
  );
}

export default AddressAutocomplete;
