
//URl Database
// export const urlDB = 'http://127.0.0.1:8000/api/v1';
// export const urlMedia = 'http://127.0.0.1:8000/';
// export const urlDB = 'https://yumesushi.ddns.net/api/v1';
// export const urlMedia = 'https://yumesushi.ddns.net/';

export const urlDB = `${process.env.REACT_APP_URL_DB}api/v1`;
export const urlMedia = process.env.REACT_APP_URL_DB;


// size
export const LAPTOP = 1280;
export const PLANE_TABLE = 768;
export const MOBILE = 468;

// size
export const SHOW_DISH_LAPTOP = { total: 12, plus: 3 };
export const SHOW_DISH_PLANE_TABLE = { total: 8, plus: 2 };
export const SHOW_DISH_MOBILE = { total: 5, plus: 2 };
