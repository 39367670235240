import { handleGeocoder, getCity } from "../geocoder";

function getUserCity() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        getCity({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }).then((latlngResponse) => {
          resolve(latlngResponse);
        }).catch((error) => {
          reject(error);
        });
      });
    });
  }

export default async function getUserLocation() {
  try {
    const geo = await getUserCity();
    return geo;
  } catch (error) {
    throw error; 
  }
}
