import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { useTranslation } from "react-i18next";
import { useFormData } from "../../contexts/FormDataContext";
import MainApi from "../../utils/MainApi";
import { NavLink } from "react-router-dom";
import { splitComment } from "../../utils/address";
import Preloader from "../Preloader/Preloader";
import { urlMedia } from "../../utils/consts";
import DishError from "../Errors/DishError";
import PopupRegistration from "../PopupDish/PopupRegistration";
import "./Payment.css";

function Payment({
  cartData,
  onSubmitOrderData,
  isTakeawayPayment,
  finalOrder,
  language,
  timeDelivery, 
  selectedCity
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { formData } = useFormData();
  const { getOrdersApi } = useContext(CurrentUserContext);
  const [isPreloader, setPreloader] = useState(false);
  const [isUnavaibleDish, setIsUnavaibleDish] = useState(false);
  const [workingHours, setWorkingHours] = useState('');
  const [isWrongTime, setIsWrongTime] = useState(false);

  useEffect(() => {
    if (!finalOrder?.amount) {
      navigate("/cart");
    }
  }, []);

  const successfulHandler = (response) => {
    localStorage.removeItem("cartDishes"); // удаляем данные о корзине из `localStorage`
    localStorage.removeItem("persons_qty");
    setPreloader(false); // Скрываем индикатор загрузки
    getOrdersApi();
    onSubmitOrderData(false);
  };

  const errorHandler = (err) => {
    setPreloader(false); // Скрываем индикатор загрузки даже в случае ошибки

    if (err.message?.orderdishes) {
      setIsUnavaibleDish(err.message.orderdishes);
    } else if (err.message?.delivery_time) {
      setIsWrongTime(true);
    } else {
      onSubmitOrderData(true);
    }
    // console.error("Ошибка при отправке заказа:", err);
  };

  const handleSubmitFinal = (event) => {
    event.preventDefault();
    setPreloader(true);
    // Формируем данные для заказа на основе введенных данных и содержимого корзины
    const orderData = {
      discounted_amount: formData.discounted_amount,
      payment_type: formData.payment_type,
      items_qty: formData.items_qty,
      recipient_name: formData.recipient_name,
      recipient_phone: formData.recipient_phone,
      city: formData.city,
      delivery_time: formData.delivery_time,
      comment: formData.comment,
      persons_qty: formData.persons_qty,
      orderdishes: formData.orderdishes,
      amount: formData.amount,
      promocode: formData.promocode,
      language: formData.language,
    };

    if (!isTakeawayPayment) {
      orderData.recipient_address = formData.recipient_address;
      orderData.coordinates = formData.coordinates || null;
      orderData.my_delivery_address = formData.my_delivery_address;

      MainApi.postDeliveryCreateMethod(orderData, language)
        .then((response) => {
          successfulHandler(response);
        })
        .catch((err) => {
          errorHandler(err);
        });
    } else {
      orderData.restaurant = formData.restaurant;
      MainApi.postTakeawayCreateMethod(orderData, language)
        .then((response) => {
          successfulHandler(response);
        })
        .catch((err) => {
          errorHandler(err);
        });
    }
  };

  const currentLanguage = i18n.language.toLocaleLowerCase();

  const translateDish = (translations, key) => {
    if (!translations) return "Название не найдено";
    // Ожидаемый формат translations - { en: { short_name: "Dish", text: "Description" }}
    const translation =
      translations[currentLanguage] || translations["sr-latn"] || {};
    return (
      translation[key] || translation["short_name"] || "Название не найдено"
    );
  };

  useEffect(() => { 
    if (isTakeawayPayment && selectedCity) { 
      setWorkingHours(selectedCity.workhours);
    } else if (!isTakeawayPayment) {
      setWorkingHours(timeDelivery[0] + "-" + timeDelivery[1]);
    }
  }, [timeDelivery, selectedCity])

  return (
    <>
      <section className="payment">
        {isPreloader && (
          <div className="preloader-abs">
            <Preloader isPreloader={isPreloader} />
          </div>
        )}
        <div className="payment_info">
          <div className="payment_del_info">
            <h4>{t("delivery.info", "Информация о заказе")}</h4>
            <div className="payment_del_text">
              <span className="payment_del_title">
                {t("delivery.your_name", "Ваше имя")}
              </span>
              <span>
                {formData.recipient_name ? formData.recipient_name : null}
              </span>
            </div>

            <div className="payment_del_text">
              <span className="payment_del_title">
                {" "}
                {t("delivery.your_phone", "Ваш телефон")}
              </span>
              <span>
                {formData.recipient_phone ? formData.recipient_phone : null}
              </span>
            </div>

            <div className="payment_del_text">
              {finalOrder.selectedStore ? (
                <>
                  <span className="payment_del_title">
                    {t("pickup.store", "Адрес")}
                  </span>
                  <span>{finalOrder.selectedStore.name}</span>
                </>
              ) : finalOrder.recipient_address ? (
                <>
                  <span className="payment_del_title">
                    {t("delivery.delivery_address", "Адрес доставки")}
                  </span>
                  <span>{finalOrder.recipient_address}</span>
                </>
              ) : null}
            </div>

            {!isTakeawayPayment && (
              <div className="payment_del_text">
                <span className="payment_del_title">
                  {t("delivery.apartment", "Квартира")}
                </span>
                <span>{splitComment(formData.comment).flat}</span>
              </div>
            )}

            <div className="payment_del_text">
              <span className="payment_del_title">
                {t("delivery.specify_date_time", "Время доставки")}
              </span>
              <span>
                {formData.delivery_time
                  ? formData.delivery_time
                  : t("dates.as_soon_as_possible", "Как можно скорее")}
              </span>
            </div>

            {formData.comment && (
              <div className="payment_del_text">
                <span className="payment_del_title">
                  {t("delivery.order_comment", "Комментарий к заказу")}
                </span>
                <span>
                  {!isTakeawayPayment ? (
                    <>{splitComment(formData.comment).comment}</>
                  ) : (
                    formData.comment
                  )}
                </span>
              </div>
            )}
          </div>
          <div className="payment_cart">
            <h4>{t("cart.in_your_cart", "В вашей корзине")}</h4>
            {cartData && cartData.length > 0 ? (
              cartData.map((cartItem, index) => {
                const translationShortName = translateDish(
                  cartItem.dish.translations,
                  "short_name"
                );
                // const totalPrice = calculateTotalPrice(
                //   cartItem.dish.final_price,
                //   cartItem.quantity
                // );
                return (
                  <div className="payment_cart-item" key={index}>
                    <p className="cart__product-name">{translationShortName}</p>
                    <div className="cart__container-count">
                      {/* <p className="cart__product-price">{totalPrice} RSD</p> */}
                      <p className="cart__product-count">{cartItem.quantity}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}

            <NavLink to="/cart">
              <p className="payment__text-link app__button-opacity">
                {t("cart.return", "Вернуться в корзину")}
              </p>
            </NavLink>
          </div>
        </div>
        <form
          className={isPreloader ? "payment_form  form--grey " : "payment_form"}
          onSubmit={handleSubmitFinal}
        >
          <div className="payment__containers">
            <div className="payment__container">
              <div className="payment__container-title">
                <h2 className="payment__title-sum">
                  {t("pickup.order_summary", "Сумма заказа:")}
                </h2>
                <p className="payment__price-sum">
                  {finalOrder?.amount ? finalOrder?.amount : 0} RSD
                </p>
              </div>
              <div className="payment__container-title">
                <h2 className="payment__title-sell">
                  {t("pickup.order_sell", "Ваша скидка:")}
                </h2>
                <p className="payment__price-sell">
                  {" "}
                  {finalOrder?.discounted_amount
                    ? finalOrder?.discounted_amount
                    : 0}{" "}
                  RSD
                </p>
              </div>

              {finalOrder?.delivery_cost ? (
                <div className="payment__container-title">
                  <h2 className="payment__title-sell">
                    {t("delivery.cost_of_delivery", "Стоимость доставки:")}
                  </h2>
                  <p className="payment__price-sell">
                    {" "}
                    {finalOrder.delivery_cost === "Requires clarification" ? (
                      <span>
                        {t(
                          "delivery.requires_clarification",
                          "Требует уточнения у администратора"
                        )}
                      </span>
                    ) : (
                      <span>{finalOrder?.delivery_cost} RSD</span>
                    )}
                  </p>
                </div>
              ) : finalOrder?.delivery_cost === 0 ? (
                <div className="payment__container-title">
                  <h2 className="payment__title-sell">
                    {t("delivery.cost_of_delivery", "Стоимость доставки:")}
                  </h2>
                  <p className="payment__price-sell">
                    {" "}
                    <span>{finalOrder?.delivery_cost} RSD</span>
                  </p>
                </div>
              ) : null}

              <div className="payment__container-title">
                <h2 className="payment__title-total">
                  {t("pickup.order_total", "Итого:")}
                </h2>
                <p className="payment__price-total">
                  {" "}
                  {finalOrder?.total ? finalOrder.total : 0} RSD
                </p>
                {/* "total": 
                                "title" "Total amount, incl. delivery",
                                "total_amount": 2925.0 */}
              </div>
            </div>
            <div className="payment__checkbox">
              <a
                className="payment__text app__button-opacity"
                href={
                  language === "ru"
                    ? `${urlMedia}/media/pdfs/RU_Soglasie_na_obrabotku_PD.pdf`
                    : language === "en"
                    ? `${urlMedia}/media/pdfs/EN_Consent_to_the_Processing_of_PD.pdf`
                    : `${urlMedia}/media/pdfs/SR_LATN_Saglasnost_za_obradu_LP.pdf`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                {t(
                  "pickup.consent_all",
                  "Размещая заказ, вы даете Согласие на обработку персональных данных."
                )}
                {}
              </a>
            </div>

            <div className="payment__submit">
              <button
                aria-label="Оформить заказ"
                type="submit"
                className={`app__button-opacity delivery__btn`}
              >
                {t("pickup.do_order", "Оформить заказ")}
              </button>
            </div>
          </div>
        </form>
        {isUnavaibleDish && (
          <DishError
            language={language}
            dishes={isUnavaibleDish}
            cartData={cartData}
          ></DishError>
        )}
        {isWrongTime && (
          <PopupRegistration
            setIsPopup={setIsWrongTime}
            message={`${t(
              "delivery.wrong_time",
              "Извините, сейчас мы закрыты, но вы можете оформить предварительный заказ, выбрав точное время выдачи заказа "
            )}` + workingHours} 
          />
        )}
      </section>
    </>
  );
}

export default Payment;
