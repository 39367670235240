import React from 'react';
import './Tag.css';

export default function Tag({ text, style }) {




    return (
        <div className="tag" style={style}>
            {text}
        </div>
    );
}