import React, { useState, useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { useFormData } from "../../contexts/FormDataContext";
import { useUtensilsCounter } from "../../hooks/useUtensils";
import useFormValidation from "../../utils/FormValidation";
import { useTranslation } from "react-i18next";
import MainApi from "../../utils/MainApi";

import DishError from "../Errors/DishError";
import StorePickerMap from "../../utils/StorePickerMap";
import Promocode from "../Promocode/Promocode";

import SelectAddress from "../Select/SelectAddress";
import DatePicker from "../UI/DatePicker/DatePicker";
import PopupRegistration from "../PopupDish/PopupRegistration";
import {
  getMonthNames,
  isAsSoonAsPossible,
} from "../../utils/DateUtils/dateUtils";

import "./Pickup.css";

function Pickup({
  isTakeaway,
  cartData,
  promoCode,
  setPromoCode,
  selectedCity,
  setFinalOrder,
  finalOrder,
  language,
  isDiscount,
  handleCitySelection,
}) {
  //  хук useFormData для доступа к состоянию и функции обновления
  const { updateFormData } = useFormData();

  // location
  const navigate = useNavigate();
  const { t } = useTranslation();
  // для выбора магазина с карты
  const [selectedStore, setSelectedStore] = useState(
    finalOrder.selectedStore || null
  );

  //каунтер до 10 приборов
  // const [persons_qty, setCount] = useState(1);
  const [loading, setLoading] = useState(false);

  //если залогинился юзер - вписать его данные
  const currentUser = useContext(CurrentUserContext);
  const {
    values,
    isValid,
    errors,
    formRef,
    setValues,
    handleChange,
    setErrors,
    checkFormValidity,
  } = useFormValidation();

  const [selectedMonth, setSelectedMonth] = useState("Как можно быстрее");

  const [persons_qty, handleUtensils, handleAdd, handleDelete] =
    useUtensilsCounter(cartData);

  // Функция для вывода дня и месяца
  const MONTH_NAMES = getMonthNames(t);

  //end

  const handlePromoChange = (code) => {
    setPromoCode(code);
  };

  const [isWrongTime, setIsWrongTime] = useState(false);

  const [stores, setStores] = useState([]);

  useEffect(() => {
    handleUtensils();
  }, []);

  useEffect(() => {
    setValues((v) => ({
      ...v,
      recipient_name:
        finalOrder.recipient_name || currentUser.currentUser?.first_name || "",
      recipient_phone:
        finalOrder.recipient_phone || currentUser.currentUser?.phone || "",
      restaurant: selectedStore?.id || "",
      // comment: "",
      persons_qty: persons_qty,
      delivery_time: values.delivery_time || "11:00",
      city: selectedCity,
      payment_type: finalOrder.payment_type || "",
    }));
    // if (!isAsSoonAsPossible(selectedMonth)) {
    //   const [day, monthName] = selectedMonth.split(" ");
    //   const monthNumber = MONTH_NAMES.indexOf(monthName);
    //   const year = new Date().getFullYear(); // или берем год из выбранной даты
    //   const date = new Date(year, monthNumber, parseInt(day, 10));
    //   setValues((v) => ({
    //     ...v,
    //     delivery_time: generateTimeOptions(date)[0] || "",
    //   }));
    // }
    checkFormValidity();
  }, [currentUser]);

  const handleDeliveryTime = () => {
    let deliveryTime = null; // Для "Как можно быстрее" отправляем null
    // Проверяем, выбрана ли конкретная дата и время
    if (!isAsSoonAsPossible(selectedMonth) && values.delivery_time) {
      const [day, monthName] = selectedMonth.split(" ");
      const monthNumber = MONTH_NAMES.indexOf(monthName) + 1; // Получаем номер месяца
      const formattedMonth = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
      const year = new Date().getFullYear();
      deliveryTime = `${day}.${formattedMonth}.${year} ${values.delivery_time}`;
    } else {
      deliveryTime = null;
    }
    return deliveryTime;
  };

  const handleDishesForOrder = () => {
    const dishesToOrder = cartData.map((dish) => {
      const article = dish.dish.article;
      const { quantity } = dish;
      return { dish: article, quantity: quantity };
    });

    return dishesToOrder;
  };

  const handleOrderData = (promoFromForm = null) => {
    if (!selectedStore) {
      // Обработка случая, когда ресторан не выбран
      console.error("Ресторан не выбран!");
      return;
    }

    const formValues = {
      discounted_amount: values.discounted_amount,
      //payment_type: values.payment_type,
      items_qty: values.items_qty,
      recipient_name: values.recipient_name,
      recipient_phone: values.recipient_phone,
      restaurant: selectedStore.id,
      city: selectedCity.city,
      delivery_time: handleDeliveryTime(), // Используем составленное или null значение,
      comment: values.comment,
      persons_qty: persons_qty,
      amount: values.amount,
      promocode: promoFromForm,
      payment_type: values.payment_type,
      language: language,
    };

    const dishes = handleDishesForOrder();

    const orderFormData = {
      ...formValues,
      orderdishes: dishes, // уже сформированный очищенный массив блюд
    };

    updateFormData(orderFormData);

    return orderFormData;
  };

  async function handleSubmit(event) {
    event.preventDefault();
    // if (promoCode) {
    //   navigate("/payment");
    // } else {
    const orderData = handleOrderData(promoCode);
    if (!orderData) {
    } // обработать что будет если ресторан не выбран
    setLoading(true);
    try {
      const res = await MainApi.postTakeawayPreChecoutMethod(
        orderData,
        language
      );

      setFinalOrder({
        ...orderData,
        discounted_amount: res.total_discount,
        amount: res.amount,
        total: res.total.total_amount,
        selectedStore: selectedStore,
      });
      setLoading(false);
      navigate("/payment");
    } catch (err) {
      if (err.message === "Ошибка: 500") {
        setErrors(() => ({
          server: true,
        }));
      } else if (err.message.delivery_time) {
        setIsWrongTime(true);
      } else {
        setErrors(() => ({
          ...err.message,
        }));
      }
      setLoading(false);
    }
  }

  const checkPromo = async (e, userPromo) => {
    e.preventDefault();
    const checkData = handleOrderData(userPromo);
    if (!checkData) return { data: "empty" };

    try {
      const res = await MainApi.postTakeawayPreChecoutMethod(
        checkData,
        language
      );
      if (!res) return null;
      setFinalOrder({
        ...checkData,
        discounted_amount: res.total_discount,
        amount: res.amount,
        total: res.total.total_amount,
        selectedStore: selectedStore,
      });
      return res;
    } catch (err) {
      if (err.message === "Ошибка: 500") {
        setErrors(() => ({
          server: true,
        }));
      } else {
        setErrors(() => ({
          ...err.message,
        }));
      }
      return { error: err.message };
    }
  };

  const getStores = () => {
    return isTakeaway
      .filter((city) => city.city === selectedCity.city)
      .map((cityInfo) =>
        cityInfo.restaurants.map((restaurant) => ({
          ...restaurant,
          cityName: cityInfo.city,
        }))
      )
      .flat()
      .map((restaurant) => ({
        id: restaurant.id,
        name: `${restaurant.cityName}, ${restaurant.address}`,
        address: restaurant.address,
        value: restaurant.id,
        label: `${restaurant.cityName}, ${restaurant.address}`, // Город и адрес
        coordinates: {
          lat: restaurant.coordinates.latitude,
          lng: restaurant.coordinates.longitude,
        },
        open_time: restaurant.open_time,
        close_time: restaurant.close_time,
      }));
  };

  useEffect(() => {
    const listOfStores = getStores();
    setStores(listOfStores);
    const restaurant = listOfStores.find(
      (store) => store.id === selectedCity.id
    );
    if (restaurant === undefined) {
      setSelectedStore(listOfStores[0]);
    } else {
      setSelectedStore(restaurant);
    }
  }, [selectedCity, isTakeaway]);

  useEffect(() => {
    checkFormValidity();
  }, [values.recipient_name, values.recipient_phone]);

  const handleSelectedStore = (store) => {
    setSelectedStore(store);
    handleCitySelection(selectedCity.city, store);
  };

  return (
    <>
      <div className="delivery">
        <form ref={formRef} className="delivery__form" onSubmit={handleSubmit}>
          <div className="delivery__description">
            <label className="delivery__label" htmlFor="recipient_name">
              {t("delivery.your_name", "Ваше имя")}
              <input
                value={values.recipient_name || ""}
                onChange={handleChange}
                id="recipient_name"
                className={`delivery__input ${
                  !values.recipient_name && "delivery__input--red"
                }`}
                name="recipient_name"
                type="text"
                placeholder={t("delivery.name", "Имя")}
                minLength="2"
                maxLength="400"
                // pattern="^[A-Za-zА-Яа-яЁё]{2,150}$"
                pattern="^[A-Za-zА-Яа-яЂ-џЈ-џђ-џљњћџČčĆćDždžĐđLjljNjnjŠšŽž0-9\s]+$"
                required
              />
              <span
                className={`${
                  errors.recipient_name ? "login__error" : "login__error_hidden"
                }`}
              >
                {errors.recipient_name ===
                "Please check your name, note that only letters are allowed." ? (
                  <>
                    {t(
                      "errors.enter_text_of_min_two_letters",
                      "Введите имя или фамилию от 2 букв. Имя не должно быть местоимением"
                    )}
                  </>
                ) : (
                  <>
                    {t("delivery.field_required", "Поле обязательно для ввода")}
                  </>
                )}
              </span>
            </label>
          </div>
          <div className="delivery__description">
            <label className="delivery__label" htmlFor="recipient_phone">
              {t("delivery.your_phone", "Ваш телефон")}
              <input
                value={values.recipient_phone || ""}
                onChange={handleChange}
                id="recipient_phone"
                className={`delivery__input ${
                  !values.recipient_phone && "delivery__input--red"
                }`}
                name="recipient_phone"
                type="tel"
                placeholder="+"
                minLength="11"
                maxLength="128"
                pattern="^\+[0-9]{11,128}$"
                required
              />
              <span
                className={`${
                  errors.recipient_phone
                    ? "login__error"
                    : "login__error_hidden"
                }`}
              >
                {errors.recipient_phone === "Enter a valid phone number."
                  ? t("errors.phone_invalid", "Введите корректный номер")
                  : t("delivery.field_required", "Поле обязательно для ввода")}
              </span>
            </label>
          </div>
          {/* <div className="delivery__description">
          {" "}
            <p>{t("delivery.your_phone", "Или укажите иной способ связи")}</p> 
              
            <select id="type_messenger">
              <option value="telegram" name="telegram">Telegram</option>
              <option value="whatsapp" name="whatsapp">WhatsApp</option>
            </select>
            <label className="delivery__label" htmlFor="recipient_phone">
              <input
                value={values.messenger_account || ""}
                onChange={handleChange}
                id="messenger_account"
                className="delivery__input"
                name="messenger_account"
                type="text"
                // placeholder="+"
                minLength="11"
                maxLength="14"
              />
              <span
                className={`${
                  errors.recipient_phone
                    ? "login__error"
                    : "login__error_hidden"
                }`}
              >
                {errors.recipient_phone === "Enter a valid phone number."
                  ? t("errors.phone_invalid", "Введите корректный номер")
                  : t("delivery.field_required", "Поле обязательно для ввода")}
              </span>
            </label>
          </div> */}
          <div className="delivery__description">
            <label className="delivery__label" htmlFor="restaurant">
              {t("delivery.store", "Выберите ресторан для самовывоза")}
              <div className="select__container">
                <SelectAddress
                  defaultValue={selectedStore}
                  options={stores}
                  id="restaurant"
                  name="restaurant"
                  value={selectedStore}
                  onChange={(option) => handleSelectedStore(option)}
                  isDisabled={false}
                ></SelectAddress>
                <span
                  className={`${
                    !selectedStore ? "login__error" : "login__error_hidden"
                  }`}
                >
                  {t("delivery.field_required", "Поле обязательно для ввода")}
                </span>
              </div>

              <StorePickerMap
                onStoreSelect={setSelectedStore}
                stores={stores}
                selectedStore={selectedStore}
              />
            </label>
          </div>
          <div className="delivery__description">
            {t("delivery.number_of_utensils", "Количество приборов")}
            <button
              onClick={handleDelete}
              aria-label="Минус"
              type="button"
              className="delivery__btn-product_delete app__button-opacity"
            ></button>
            <span className="delivery__product-count">{persons_qty}</span>
            <button
              onClick={handleAdd}
              aria-label="Плюс"
              type="button"
              className="delivery__btn-product_add app__button-opacity"
            ></button>
          </div>
          <div className="delivery__description">
            <label className="delivery__label">
              {t("delivery.order_comment", "Комментарий к заказу")}
              <textarea
                className="delivery__textarea"
                value={values.comment}
                name="comment"
                maxLength="1500"
                onChange={handleChange}
                placeholder={t(
                  "delivery.write_your_order_comments",
                  "Напишите свои комментарии к Вашему заказу"
                )}
              />
            </label>
          </div>

          <DatePicker
            setValues={setValues}
            handleChange={handleChange}
            values={values}
            setSelectedMonth={setSelectedMonth}
            selectedMonth={selectedMonth}
            workingHours={{
              startHour: selectedCity?.open_time
                ? selectedCity.open_time?.substring(0, 2)
                : "11",
              endHour: selectedCity?.close_time
                ? selectedCity.close_time?.substring(0, 2)
                : "22",
            }}
            selectedCity={selectedCity}
          />

          {errors.server && (
            <p className="login__error">
              {t(
                "errors.other_errors",
                "Кажется произошла ошибка. Если она повториться, перезвоните нам"
              )}
            </p>
          )}

          <h3
            className={
              values.payment_type
                ? " payment_choose"
                : "payment_choose--red payment_choose"
            }
          >
            {t("pickup.choose_payment_method", "Выберите способ оплаты")}
          </h3>
          <div className="payment__area">
            <div className="payment__area-box">
              <div className="delivery__description">
                <input
                  value="cash"
                  onChange={handleChange}
                  id="cash"
                  className="payment__input"
                  name="payment_type"
                  type="radio"
                  checked={values.payment_type === "cash" ? true : false}
                  required
                />
                <label className="payment__btn-cash" htmlFor="cash"></label>
              </div>
              <p className="payment__btn-text_cash">
                {t("pickup.payment_cash", "Наличными курьеру")}
              </p>
            </div>
            <div className="payment__area-box">
              <div className="delivery__description">
                <input
                  value="card_on_delivery"
                  onChange={handleChange}
                  id="card_on_delivery"
                  className="payment__input"
                  name="payment_type"
                  type="radio"
                  checked={
                    values.payment_type === "card_on_delivery" ? true : false
                  }
                  required
                />
                <label
                  className="payment__btn-card"
                  htmlFor="card_on_delivery"
                ></label>
              </div>
              <p className="payment__btn-text_card">
                {t("pickup.payment_card", "Картой курьеру")}
              </p>
            </div>
          </div>
          {isDiscount && language === "ru" && (
            <p className="discount">При оплате наличными скидка 10%</p>
          )}
          <Promocode
            globalPromo={promoCode}
            handleSubmitPromo={checkPromo}
            handlePromoChange={handlePromoChange}
          ></Promocode>

          <button
            onClick={handleSubmit}
            className={`delivery__btn ${
              !isValid || !selectedStore
                ? "delivery__btn-save_disable"
                : "app__button-opacity"
            } ${loading && "button--loading"}`}
            disabled={
              !isValid ||
              !selectedStore ||
              !values.recipient_name ||
              !values.recipient_phone ||
              (!isAsSoonAsPossible(selectedMonth) && !values.delivery_time)
            }
            type="button"
            aria-label="Выберете способ оплаты"
          >
            {!loading && <> {t("delivery.continue", "Далее")}</>}
          </button>
        </form>
        {errors.orderdishes && (
          <DishError
            language={language}
            dishes={errors.orderdishes}
            cartData={cartData}
          ></DishError>
        )}

        {isWrongTime && (
          <PopupRegistration
            setIsPopup={setIsWrongTime}
            message={`${t(
              "delivery.wrong_time",
              "Извините, сейчас мы закрыты, но вы можете оформить предварительный заказ, выбрав точное время выдачи заказа"
            )} ${
              selectedStore
                ? selectedStore.open_time + "-" + selectedStore.close_time
                : ""
            }`}
          />
        )}
      </div>
    </>
  );
}

export default Pickup;
