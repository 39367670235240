// import React, { useState, useEffect } from "react";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
// import Loader from "../components/UI/Loader/Loader";
// import { useTranslation } from "react-i18next";

// //хук для отображение карт
// function MyGoogleMap({ locations, numberOfReataraunt }) {
//   const [mapDimensions, setMapDimensions] = useState({
//     width: "100%",
//     height: "300px",
//     margin: "20px 0",
//   });
//   const { t } = useTranslation();

//   useEffect(() => {
//     const handleResize = () => {
//       // Когда размер окна изменяется
//       // можно задать логику для изменения размеров карты в зависимости от размеров окна
//       setMapDimensions({
//         width: window.innerWidth < 2000 ? "100%" : "300px",
//         height: "300px",
//         margin: "20px 0",
//       });
//     };

//     // Добавляем обработчик события при монтировании компонента
//     window.addEventListener("resize", handleResize);

//     // Вызываем обработчик немедленно, чтобы установить начальное состояние
//     handleResize();

//     // Удалеем обработчик события при размонтировании компонента
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   // Объявляем переменную bounds внутри компонента
//   let bounds;

//   console.log({ ...locations.coordinates })
//   // Проверяем, что API карт Google загружен
//   if (window.google && window.google.maps) {
//     bounds = new window.google.maps.LatLngBounds();
//     // locations.forEach((location) => {
//     //   bounds.extend(location);
//     // });
//     bounds.extend({ ...locations.coordinates });
//   }

//   const onLoad = (map) => {
//     const bounds = new window.google.maps.LatLngBounds();
//     // locations.forEach((location) => {
//     //   bounds.extend(new window.google.maps.LatLng(location.lat, location.lng));
//     // });
//     bounds.extend(new window.google.maps.LatLng(locations.latitude, locations.longitude));
//     // if (map && locations.length > 1) {
//     //   map.fitBounds(bounds, { top: 100, bottom: 100, left: 100, right: 100 });

//     // } else {
//     //   map.setCenter(locations[0]);
//     // }
//     map.fitBounds(bounds, { top: 100, bottom: 100, left: 100, right: 100 });
//   };

//   return (
//     <LoadScript
//       googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
//       loadingElement={
//         <Loader text={t("addresses.loading", "Загружаем карту...")} />
//       }
//     >
//       <GoogleMap
//         mapContainerStyle={mapDimensions}
//         zoom={13}
//         onLoad={onLoad}
//         center={locations.coordinates}
//         options={{ streetViewControl: false }}
//       >
//         {/* {locations.map(
//           (location, index) =>
//             index === numberOfReataraunt && (

//             )
//         )} */}
//         <Marker  position={locations.coordinates} />
//       </GoogleMap>
//     </LoadScript>
//   );
// }

// export default MyGoogleMap;
import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import Loader from "../components/UI/Loader/Loader";
import { useTranslation } from "react-i18next";

//хук для отображение карт
const MyGoogleMap = ({ locations, numberOfReataraunt }) => {
  const [mapDimensions, setMapDimensions] = useState({
    width: "100%",
    height: "60vh",
    maxHeight: "900px",
    margin: "0 0",
  });
  const { t } = useTranslation();

  // useEffect(() => {
  //   const handleResize = () => {
  //     // Когда размер окна изменяется
  //     // можно задать логику для изменения размеров карты в зависимости от размеров окна
  //     setMapDimensions({
  //       width: window.innerWidth < 2000 ? "100%" : "300px",
  //       height: "300px",
  //     });
  //   };

  //   // Добавляем обработчик события при монтировании компонента
  //   window.addEventListener("resize", handleResize);

  //   // Вызываем обработчик немедленно, чтобы установить начальное состояние
  //   handleResize();

  //   // Удалеем обработчик события при размонтировании компонента
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // Объявляем переменную bounds внутри компонента
  let bounds;

  // Проверяем, что API карт Google загружен
  if (window.google && window.google.maps) {
    bounds = new window.google.maps.LatLngBounds();
    locations.forEach((location) => {
      bounds.extend(location);
    });
  }

  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map && locations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach((location) => {
        bounds.extend(location);
      });
      const mapStyle = window.innerWidth < 661 ? { top: 0, bottom: 0, left: 0, right: 0 } : { top: 100, bottom: 100, left: 300, right: 100 };
      map.fitBounds(bounds, mapStyle);
      if (locations.length === 1) {
        map.setZoom(13);
      }
    }
    
  }, [map, locations]);

  // const onLoad = (map) => {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   locations.forEach((location) => {
  //     bounds.extend(new window.google.maps.LatLng(location.lat, location.lng));
  //   });
  //   if (map && locations.length > 1) {
  //     map.fitBounds(bounds, { top: 100, bottom: 100, left: 100, right: 100 });
  //   } else {
  //     map.setCenter(locations[0].lat, locations[0].lng);
  //   }
  // };

  function createKey(location) {
    return location.lat + location.lng;
  }

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      loadingElement={
        <Loader text={t("addresses.loading", "Загружаем карту...")} />
      }
    >
      <GoogleMap
        mapContainerStyle={mapDimensions}
        zoom={12}
        onLoad={(map) => setMap(map)}
        // onLoad={onLoad}
        options={{ streetViewControl: false }}
        id="contactsMap"
      >
        <MarkerClusterer maxZoom={12} zoom={12}>
          {(clusterer) =>
            locations.map((location) => (
              <Marker
                key={createKey(location)}
                position={location}
                clusterer={clusterer}
              />
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    </LoadScript>
  );
};

export default React.memo(MyGoogleMap);
