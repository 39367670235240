import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./PopupDish.css";

function PopupRegistration({ message, ...props }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setIsPopup } = props;

  const onClose = () => {
    if (message === "delivery.order_successful") {
      setIsPopup(false);
      navigate("/");
    } else if (message === "registr.success") {
      setIsPopup(false);
      navigate("/login");
    } else {
      setIsPopup(false);
    }
  };

  return (
    <div className={message ? "popup popup_open" : "popup"}>
      <div className="popup__container popup--information">
        <div className="popup-timer"></div>
        <div className="popup__container-column">
          <div className="popup__title">
            <h2 className="popup__image-text">
              {message === "delivery.order_successful" ? (
                <>
                  {t(
                    "delivery.order_successful",
                    "Ваш заказ принят. Скоро мы с вами свяжемся для подтверждения."
                  )}
                </>
              ) : message === "errors.other_errors" ? (
                <>
                  {t(
                    "errors.other_errors",
                    "Что-то пошло не так, ваш заказ не отправлен. Попробуйте повторить снова."
                  )}
                </>
              ) : message === "registr.success" ? (
                <>
                  {t(
                    "registr.success",
                    "Регистрация прошла успешно! На указанный e-mail отправлена ссылка для активации аккаунта."
                  )}
                </>
              ) : (
                message
              )}
            </h2>
          </div>
        </div>
        <button
          onClick={onClose}
          className="popup__close-button"
          type="button"
          aria-label="Закрыть попап"
        ></button>
      </div>
    </div>
  );
}

export default PopupRegistration;
