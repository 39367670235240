import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../PopupDish/PopupDish.css";
import "./dishError.css";

function DishError({ language, dishes, cartData }) {
  const [dishError, setDishError] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setDishError(() => []);
    dishes.map((dish) => {
      if (dish.dish) {
        const dishErr = cartData.find(
          (element) => element.dish?.article == dish.dish.pk
        );
        setDishError((prev) => [
          ...prev,
          dishErr.dish.translations[language].short_name,
        ]);
      }
    });
  }, []);


  return (
    <div>
      {dishError && (
        <div className="popup popup_open popup--error">
          <div className="popup__container popup--information">
            <div className="popup__container-column">
              <div className="popup__title--error">
                <p>
                  {t(
                    "errors.dish_not_available",
                    "Эти роллы стали недоступны:"
                  )}
                </p>
                {dishError.map((item, i) => {
                  return (
                    <p className="popup__text " key={i}>
                      {item}
                    </p>
                  );
                })}

                <NavLink to="/cart">
                  <button className="popup__button app__button-opacity">
                    {t("cart.return", "Вернуться в корзину")}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DishError;
