import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../Cart/Cart.css";

export default function Promocode({
  globalPromo,
  handleSubmitPromo,
  handlePromoChange,
}) {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [promocode, setPromocode] = useState("");

  const onSubmit = async (e) => {
    setLoading(true);
    try {
      const result = await handleSubmitPromo(e, promocode);
      if (!result) {
        setError("errors.promo_error");
      }

      // Ошибки при неудачном запросе Bad req
      if (result.error && result.error.promocode) {
        result.error.promocode.detail === "Please check the promocode."
          ? setError("errors.promo_notfound")
          : setError("errors.promo_error");
        // Проверяем на любую ошибку промокода
      }

      if (result.error && !result.error.promocode) {
        setError("errors.promo_all_fields");
      }

      if (result.data && result.data === "empty") {
        setError("errors.promo_all_fields"); // Ошибка при всех пустых полях
      }

      // Проверка промокода при удачном запросе
      if (result.promocode?.valid === "invalid") {
        setError("errors.promo_notfound");
      }
      if (result.promocode?.valid === "valid") {
        setIsSuccess(true);
        handlePromoChange(promocode); // обновляем глобальное состояние промо
        setError(false);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("errors.promo_error");
    }
  };

  useEffect(() => {
    setPromocode(globalPromo);
    if (globalPromo) {
      setIsSuccess(true);
    } else {
      setIsSuccess(false);
    }
  }, [globalPromo]); // если есть сохранённый глобально промокод - подтягиваем его

  const resetPromo = () => {
    handlePromoChange(""); // обновляем глобальное состояние промо
    setIsSuccess(false);
    setError(false);
    setPromocode("");
  };

  const onClick = (e) => {
    isSuccess ? resetPromo() : onSubmit(e);
  };

  const deleteError = () => {
    if (error) {
      setError(false);
    }
  };

  return (
    <div className="cart__promo">
      {/* {t("cart.promo_code", "Промокод:")} */}
      {t("cart.helptext", "Если у вас есть промокод, введите его здесь:")}
      <div className="cart__promo-container">
        <label className="cart__promo-label" htmlFor="promo">
          <input
            id="promo"
            className={
              isSuccess
                ? "cart__promo-input--success cart__promo-input"
                : "cart__promo-input"
            }
            name="promo"
            type="text"
            pattern="^[A-Za-z0-9]+$"
            value={promocode || ""}
            onChange={(e) => {
              setPromocode(e.target.value);
              deleteError();
            }}
            disabled={isSuccess ? true : false}
            minLength="6"
            maxLength="8"
            placeholder={t("cart.promo_code", "Промокод")}
          />
        </label>
        <button
          aria-label={
            isSuccess ? <>Отменить</> : <> {t("cart.apply", "Применить")}</>
          }
          type="button"
          className={
            loading
              ? "button--loading cart__btn-promo"
              : isSuccess
              ? "cart__btn-promo--cancel"
              : promocode?.length === 0
              ? "cart__btn-promo--disable"
              : "cart__btn-promo "
          }
          onClick={onClick}
          disabled={promocode?.length > 0 ? false : true}
        >
          {loading ? (
            <> </>
          ) : isSuccess ? (
            <>
              <svg
                fill="#000000"
                width="28px"
                height="28px"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g>
                    {" "}
                    <path d="M13,3.05A7,7,0,1,0,13,13,7,7,0,0,0,13,3.05ZM12,12A5.6,5.6,0,0,1,4,12,5.61,5.61,0,0,1,4,4,5.6,5.6,0,0,1,12,4,5.61,5.61,0,0,1,12,12ZM10.65,4.08,8,6.73,5.35,4.08,4.08,5.35,6.73,8,4.08,10.65l1.27,1.27L8,9.27l2.65,2.65,1.27-1.27L9.27,8l2.65-2.65Z"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
            </>
          ) : (
            <>{t("cart.apply", "Применить")}</>
          )}
        </button>
      </div>
      <div className="promo__error">
        {error && <>{t(error) && <p>{t(error)}</p>}</>}
      </div>
    </div>
  );
}
