import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Loader from "../components/UI/Loader/Loader";
import { useTranslation } from "react-i18next";

//хук для выбора магазина
function StorePickerMap({ onStoreSelect, stores, selectedStore }) {

  const { t } = useTranslation();
  const defaultCenter =
    stores.length > 0 ? stores[0].coordinates : { lat: 0, lng: 0 };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      loadingElement={
        <Loader text={t("addresses.loading", "Загружаем карту...")} />
      }
    >
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        center={selectedStore ? selectedStore.coordinates : defaultCenter}
        zoom={15}
      >
        {stores.map((store) => (
          <Marker
            key={store.id}
            position={store.coordinates}
            onClick={() => onStoreSelect(store)} // Вызов функции при клике на маркер
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(StorePickerMap);
