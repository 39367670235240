import { useState, useCallback, useEffect } from "react";

export function useUtensilsCounter(cartData) {
  const [persons_qty, setCount] = useState(0);
  const [additionalUtensils, setAdditionalUtensils] = useState(0);

  useEffect(() => {
    const localStorageQty = localStorage.getItem("persons_qty");

    if (localStorageQty) {
      setAdditionalUtensils(() => parseInt(localStorageQty, 10));
    } else {
      localStorage.setItem("persons_qty", additionalUtensils);
    }
  }, []);

  const handleAdd = () => {
    setCount((prevCount) => (prevCount < 50 ? prevCount + 1 : prevCount));
    localStorage.setItem("persons_qty", additionalUtensils + 1);
    setAdditionalUtensils((prevCount) => prevCount + 1);
  };

  const handleDelete = () => {
    setCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
    const newValue = additionalUtensils > 1 ? additionalUtensils - 1 : 0;
    setAdditionalUtensils(newValue);
    localStorage.setItem("persons_qty", newValue);
  };

  const handleUtensils = useCallback(() => {
    let newCount = 0;

    cartData.forEach((dish) => {
      const { quantity } = dish;
      const { utensils } = dish.dish;
      newCount += utensils * quantity;
    });
    
    const additionalLocalStorageQty = localStorage.getItem("persons_qty");
    newCount += parseInt(additionalLocalStorageQty, 10);

    setCount((prevCount) => (newCount < 50 ? newCount : prevCount));
  }, [cartData]);

  return [persons_qty, handleUtensils, handleAdd, handleDelete];
}
