export const getMonthNames = (t) => {
  return t("months", {
    returnObjects: true,
    defaultValue: [
      "Января",
      "Февраля",
      "Марта",
      "Апреля",
      "Мая",
      "Июня",
      "Июля",
      "Августа",
      "Сентября",
      "Октября",
      "Ноября",
      "Декабря",
    ],
  });
};

export const isAsSoonAsPossible = (str) => {
  return !/[0-9]/.test(str);
};

export const generateDateOptions = (t) => {
  const MONTH_NAMES = getMonthNames(t);
  const today = new Date();
  const dates = [t("dates.as_soon_as_possible", "Как можно быстрее")];
  // Определяем дату на месяц вперед от сегодня
  const oneMonthFromNow = new Date();
  oneMonthFromNow.setMonth(today.getMonth() + 1);
  let dateIterator = new Date(today);
  while (dateIterator <= oneMonthFromNow) {
    const day = dateIterator.getDate();
    const month = dateIterator.getMonth();
    //const year = dateIterator.getFullYear(); // Если нужно, можно добавить год
    // Форматируем число, добавляя ведущий ноль, если нужно
    const formattedDay = day < 10 ? `0${day}` : day;
    const monthName = MONTH_NAMES[month];
    const date = `${formattedDay} ${monthName}`; // ${year}, если нужен год
    dates.push(date);
    // Переходим к следующему дню
    dateIterator.setDate(dateIterator.getDate() + 1);
  }
  return dates;
};

export const generateTimeOptions = (selectedDate, workingHours) => {
    const intervals = [];
    const currentDateTime = new Date();
    const selectedDateAsString = selectedDate.toDateString();
    let isToday = selectedDateAsString === currentDateTime.toDateString();
    // let startHour = isToday ? currentDateTime.getHours() : 11;
    let startHour = workingHours.startHour;
    // Если сегодня и текущие минуты >= 30, переходим к следующему часу
    if (isToday && currentDateTime.getMinutes() >= 30) {
      startHour++;
    }
    for (let hour = startHour; hour <= workingHours.endHour; hour++) {
      // Если выбрана сегодняшняя дата и текущий час уже прошёл, пропускаем добавление
      if (!(isToday && hour <= currentDateTime.getHours())) {
        // Добавляем полные часы
        // intervals.push(`${hour}:00`);
        intervals.push(`${hour}:${hour === startHour ? "30" : "00"}`);

        // Добавляем полчаса только если это не последний час и не первый интервал, когда сегодня и текущее время > 30 минут
        if (
          hour < workingHours.endHour &&
          hour > workingHours.startHour &&
          !(isToday && hour === startHour && currentDateTime.getMinutes() >= 30)
        ) {
          intervals.push(`${hour}:30`);
        }
      }
    }
    return intervals;
  };
