import React, { useState, useContext, useEffect } from "react";
import useFormValidation from "../../utils/FormValidation";
import ProfileNav from "../ProfileNav/ProfileNav";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { useTranslation } from "react-i18next";
import MyAddressAutocomplete from "../../utils/MyAddressAutocomplete";
import AddressAutocomplete from "../../utils/AddressAutocomplete";
import { prepareAddress } from "../../utils/address";
import SelectAddress from "../Select/SelectAddress";
import "./MyAddress.css";

function MyAddress({ isDelivery, selectedCity }) {
  const { t } = useTranslation();

  const {
    currentUser,
    getAddressApi,
    addresses,
    handleDeleteAddress,
    handlePostUserAddress,
  } = useContext(CurrentUserContext);
  const {
    values,
    isValid,
    errors,
    setErrors,
    setValues,
    formRef,
    handleChange,
    handleInput,
    checkFormValidity,
  } = useFormValidation();
  const [isShownInputs, setShownInputs] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [cityForAddress, setCityForAddress] = useState({});

  useEffect(() => {
    if (isDelivery) {
      setCityOptions(
        isDelivery.map((city) => ({
          value: city.city,
          label: city.city,
          name: city.city,
          ...city,
        }))
      );
    }
  }, [isDelivery]);

  useEffect(() => {
    setCityForAddress(
      cityOptions.find((option) => option.value === selectedCity.city)
    );
  }, [cityOptions]);

  // Показывает форму ввода
  function handleShowInput() {
    setShownInputs(true);
  }

  // Скрывает форму ввода
  function handleHideInput() {
    getAddressApi();
    setShownInputs(false);
  }

  useEffect(() => {
    setValues({
      address: currentUser.address || "",
    });
  }, [currentUser, setValues]);

  // Проверка одинаковых адресов и запрет на их добавление
  function isDuplicateAddress(newAddress, addresses) {
    // Поскольку адрес представлен одной строкой, проверка на уникальность выполняется путем сравнения строк адресов
    return addresses.some((address) => address.address === newAddress);
  }

  //   function handleSubmit(event) {
  //     event.preventDefault();
  //     const newAddress = values.address || "";
  //     if (isValid) {
  //       if (addresses.length < 3) {
  //         if (isDuplicateAddress(newAddress, addresses)) {
  //           alert("Такой адрес уже существует!");
  //         } else {
  //           onPostAddress({ address: newAddress }, handleHideInput);
  //         }
  //       } else {
  //         alert("Нельзя добавить более трех адресов");
  //       }
  //     }
  //   }

  function handleSubmit(event) {
    event.preventDefault();

    const getAddress = prepareAddress(
      values.recipient_address,
      values.private_home,
      values.home,
      values.flat,
      values.floor,
      values.homephone
    );

    if (!getAddress.isHome) {
      setErrors(() => ({ ["address"]: "addresses.enter_your_full_address" }));
      return;
    }

    const newAddress = {
      city: cityForAddress.city,
      address: getAddress?.addressWithHome[0],
      coordinates: values.coordinates,
      flat: values.flat,
      floor: values.floor,
      interfon: values.homephone,
    };

    if (isValid) {
      if (addresses.length < 3) {
        if (isDuplicateAddress(newAddress, addresses)) {
          alert("Такой адрес уже существует!");
        } else {
          handlePostUserAddress(newAddress, handleHideInput);
        }
      } else {
        alert("Нельзя добавить более трех адресов");
      }
    }
  }

  async function handleDelete(id) {
    // await onDeleteAddress(id);
    // getAddressApi();
    handleDeleteAddress(id);
  }

  const deleteError = () => {
    setErrors(() => ({ ["address"]: "" }));
  };

  const handleUpdateAddress = (place) => {
    if (!place) return;
    const addressComponents = place.address_components;
    const formattedAddress = place.formatted_address;
    // Проверка на наличие в адресе страны и улицы
    const hasCountry = addressComponents.some((component) =>
      component.types.includes("country")
    );
    const hasRoute = addressComponents.some((component) =>
      component.types.includes("route")
    );
    // Если есть страна и улица, то считаем адрес валидным.
    const isValidAddress = hasCountry && hasRoute;
    setValues((prevState) => ({
      ...prevState,
      recipient_address: formattedAddress,
      coordinates: `${place.geometry.location.lat()}, ${place.geometry.location.lng()}`,
      isAddressValid: isValidAddress, // Добавление нового состояния isAddressValid
    }));
    deleteError();
    checkFormValidity();
  };

  const handleCityChange = (selectedOption) => {
    const cityData = cityOptions.find(
      (option) => option.city === selectedOption.value
    );
    setCityForAddress(cityData);
  };

  return (
    <>
      <ProfileNav />
      <section className="adress">
        <h2 className="adress__title">
          {t("addresses.your_addresses", "Ваши адреса")}
        </h2>
        <div className="adress__container">
          {addresses.length === 0 && !isShownInputs && (
            <>
              <p className="adress__text">
                {t(
                  "addresses.no_addresses_added",
                  "Нет добавленных адресов. Вы можете добавить три адреса."
                )}
              </p>
              <button
                onClick={handleShowInput}
                type="button"
                aria-label="Добавить адрес"
                className="adress__submit-button app__button-opacity"
              >
                {t("addresses.add_address", "Добавить адрес")}
              </button>
            </>
          )}
          {isShownInputs && (
            <form
              ref={formRef}
              className="adress__form"
              onSubmit={handleSubmit}
            >
              <div className="delivery__description">
                <label className="delivery__label" htmlFor="city">
                  {t("header.choseCity", "Город")}
                  <SelectAddress
                    defaultValue={
                      cityOptions.find(
                        (option) => option.value === selectedCity.city
                      ) || cityOptions[0]
                    }
                    options={cityOptions}
                    id="city"
                    name="city"
                    value={cityOptions.find(
                      (option) => option.value === cityForAddress
                    )}
                    onChange={handleCityChange}
                    // isDisabled={isNewAddress}
                  ></SelectAddress>
                </label>
              </div>
              <div className="adress__container">
                <label className="adress__label" htmlFor="address">
                  <AddressAutocomplete
                    inputClassName="adress__input"
                    updateAddress={handleUpdateAddress}
                    values={values}
                    handleChange={handleChange}
                    handleInput={handleInput}
                    // deleteError={deleteError}
                    selectedCity={cityForAddress}
                  />
                  <span
                    className={`${
                      errors.address
                        ? "profile__error"
                        : "profile__error_hidden"
                    }`}
                  >
                    {t(
                      "addresses.enter_your_full_address",
                      "Выберите улицу и дом"
                    )}
                  </span>
                </label>
              </div>

              <>
                <div className="delivery__description">
                  <label className="delivery__label" htmlFor="flat">
                    {t("delivery.apartment", "Квартира")}
                    <input
                      value={values.flat || ""}
                      onChange={handleChange}
                      id="flat"
                      className="delivery__input"
                      name="flat"
                      type="text"
                      placeholder={t("delivery.apartment", "Квартира")}
                      minLength="1"
                      maxLength="120"
                    />
                    <span
                      className={`${
                        errors.flat ? "login__error" : "login__error_hidden"
                      }`}
                    >
                      {t(
                        "delivery.field_required",
                        "Поле обязательно для ввода"
                      )}
                    </span>
                  </label>
                </div>
                <div className="delivery__description">
                  <label className="delivery__label" htmlFor="floor">
                    {t("delivery.floor", "Этаж")}
                    <input
                      value={values.floor || ""}
                      onChange={handleChange}
                      id="floor"
                      className="delivery__input"
                      name="floor"
                      type="text"
                      placeholder={t("delivery.floor", "Этаж")}
                      minLength="1"
                      maxLength="120"
                    />
                    <span
                      className={`${
                        errors.floor ? "login__error" : "login__error_hidden"
                      }`}
                    >
                      {t(
                        "delivery.field_required",
                        "Поле обязательно для ввода"
                      )}
                    </span>
                  </label>
                </div>
                <div className="delivery__description">
                  <label className="delivery__label" htmlFor="homephone">
                    {t("delivery.intercom", "Домофон")}
                    <input
                      value={values.homephone || ""}
                      onChange={handleChange}
                      id="homephone"
                      className="delivery__input"
                      name="homephone"
                      type="text"
                      placeholder={t("delivery.intercom", "Домофон")}
                      minLength="1"
                      maxLength="120"
                    />
                    <span
                      className={`${
                        errors.homephone
                          ? "login__error"
                          : "login__error_hidden"
                      }`}
                    >
                      {t(
                        "delivery.field_required",
                        "Поле обязательно для ввода"
                      )}
                    </span>
                  </label>
                </div>
              </>

              <button
                // disabled={!isValid || !values.isAddressValid} // Используем состояние isAddressValid для управления доступностью кнопки
                disabled={!isValid}
                type="submit"
                aria-label="Сохранить адрес"
                //|| !values.isAddressValid ?
                className={`adress__submit-button ${
                  !isValid
                    ? "adress__submit-button_disable"
                    : "app__button-opacity"
                }`}
              >
                {t("addresses.save_address", "Сохранить адрес")}
              </button>
            </form>
          )}
          {addresses.length >= 0 &&
            !isShownInputs &&
            addresses.map((address) => (
              <div key={address.id} className="adress__displayed">
                <p className="adress__field">
                  {t("addresses.your_address", "Ваш Адрес:")}{" "}
                  {address.city + ", "} {address.address}
                  {address.flat && (
                    <>
                      , {t("addresses.your_flat", "квартира")} {address.flat}
                    </>
                  )}
                  {address.floor && (
                    <>
                      , {t("addresses.your_floor", "этаж")} {address.floor}
                    </>
                  )}
                  {address.interphon && (
                    <>
                      , {t("addresses.your_interphone", "домофон")}{" "}
                      {address.interphon}
                    </>
                  )}
                </p>
                <button
                  onClick={() => handleDelete(address.id)}
                  className="app__text-opacity adress__delete-button"
                  type="button"
                  aria-label="Удалить выбранный адрес"
                >
                  {t("addresses.delete_this_address", "Удалить этот адрес")}
                </button>
              </div>
            ))}
          {addresses.length < 3 && addresses.length !== 0 && !isShownInputs && (
            <>
              <button
                onClick={handleShowInput}
                type="button"
                aria-label="Добавить еще адрес"
                className="adress__submit-button app__button-opacity"
              >
                {t("addresses.add_another_address", "Добавить еще адрес")}
              </button>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default MyAddress;
