import React from "react";
import { Link, NavLink } from "react-router-dom";
import HeaderBurger from "../HeaderBurger/HeaderBurger";
import SelectPlace from "../UI//Dialog/SelectPlace";
import logo from "../../images/logo.png";
import cart from "../../images/cart.svg";
import "./HeaderMenu.css";
import { useTranslation } from "react-i18next";

function HeaderMenu({
  handleBurgerHeader,
  cartData,
  selectedCity,
  aboutUs,
  onCitySelected,
}) {
  const { t } = useTranslation();

  // Функция для подсчета товаров в корзине
  const getCountOfCartItems = () => {
    let count = 0;
    // Перебираем все элементы и суммируем их количество
    for (const item of cartData) {
      count += item.quantity;
    }
    return count;
  };

  return (
    <section className="menu">
      <button
        onClick={handleBurgerHeader}
        className="header__button-burger app__button-opacity"
        aria-label="Бургер"
      ></button>
      <div className="header__nav">
        <HeaderBurger />
      </div>
      <Link to="/">
        <img
          src={logo}
          alt="Логотип сайта Movie"
          className="header-menu__logo app__button-opacity"
        />
      </Link>

      {/* {aboutUs && (
        <div className="address__container address__container--burger">
          <div className="header__address">
            <span>{selectedCity?.city}</span>
            <span>
              {selectedCity?.address
                ? selectedCity?.address
                : `${t("cart.delivery", "Доставка")}`}
            </span>
          </div>
          <SelectPlace
            selectedCity={selectedCity}
            aboutUs={aboutUs}
            handleCitySelection={onCitySelected}
          />
        </div>
      )} */}

      <NavLink className="header-menu__link-button" to="/cart">
        <button
          className="header-menu__cart-button app__button-opacity"
          type="submit"
          aria-label="Корзина"
        >
          <img
            src={cart}
            alt="Логотип корзины"
            className="header__image-button-cart"
          />
          <p className="header__submit-button-counters">
            {getCountOfCartItems()}
          </p>
        </button>
      </NavLink>
    </section>
  );
}

export default HeaderMenu;
