import { styled } from "@mui/material";
import { Button as __Button } from "@mui/material";


export const Button = styled(__Button)`
  border-radius: 20px;
  color: #000000;
  height: 45px;
  min-width: 20px;
  width: 20px;
  padding: 0 5px 0 0;
  background-color: transparent;
  &:hover {
    transition: 1s ease;
    color: rgb(255, 48, 85);
  }

  & .selectPlace__city {
    font-size: 13px;
    font-weight: 600;
  }

  & .selectPlace__place { 
    font-size: 10px;
    font-weight: 400;
    color: #000000;
  }
`;
