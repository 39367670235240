//хук для активации юзера с почты
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Preloader from "../components/Preloader/Preloader";
import { useTranslation } from "react-i18next";
import { urlDB } from "./consts";
import "../components/App/App.css";

const ActivationPage = ({ isPreloader, language }) => {
  const { t } = useTranslation();

  const { uid, token } = useParams();
  const navigate = useNavigate();
  const [isNotValid, setIsNotValid] = useState(false);

  useEffect(() => {
    fetch(`${urlDB}/auth/users/activation/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": language,
      },
      body: JSON.stringify({ uid, token }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text().then((text) => (text ? JSON.parse(text) : {}));
        } else {
          throw new Error("Ошибка активации");
        }
      })
      .then((data) => {
        // Обработка успешной активации - переадресация на страницу входа
        navigate("/login");
      })
      .catch((error) => {
        // Обработка ошибок активации
        setIsNotValid(true);
        console.error(error);
      });
  }, [uid, token, navigate]); // Зависимости useEffect, чтобы он выполнялся один раз при монтировании компонента

  return (
    <div>
      <h1 className="activation">
        {isNotValid ? (
          <>{t("activation.error", "Ссылка устарела")}</>
        ) : (
          <>{t("activation.activating", "Активация...")}</>
        )}
      </h1>

      {isPreloader && <Preloader />}
    </div>
  );
};

export default ActivationPage;
