// хук токенов, логина и логаута
import React, { createContext, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as apiAuth from "../utils/apiAuth";

export const AuthContext = createContext();
const getRefreshToken = () => {
  return localStorage.getItem("logInJwtRefresh")
    ? localStorage.getItem("logInJwtRefresh")
    : null;
};
const getAccessToken = () => {
  return localStorage.getItem("logInJwt")
    ? localStorage.getItem("logInJwt")
    : null;
};

export const AuthProvider = ({ children }) => {
  // const [accessToken, setAccessToken] = useState(() =>
  //   localStorage.getItem("logInJwt") ? localStorage.getItem("logInJwt") : null
  // );
  // const [refreshToken, setRefreshToken] = useState(() =>
  //   localStorage.getItem("logInJwtRefresh")
  //     ? localStorage.getItem("logInJwtRefresh")
  //     : null
  // );
  const [logIn, setLogIn] = useState(false);
  const navigate = useNavigate();
  const [isPreloader, setPreloader] = useState(false);

  function logout() {
    localStorage.removeItem("coupons");
    localStorage.removeItem("cartDishes");
    localStorage.removeItem("logInJwt");
    localStorage.removeItem("logInJwtRefresh");
    navigate("/login");
    setLogIn(false);
    // setCurrentUser({});
  }

  const updateToken = async () => {
    const refreshToken = localStorage.getItem("logInJwtRefresh");
    apiAuth
      .tokenRefresh(refreshToken)
      .then((res) => {
        // const data = JSON.stringify(res);
        if (res.access) {
          localStorage.setItem("logInJwt", res.access);
        }
        // Обычно рефреш-токены не нужно менять с каждым обновлением доступа, но если сервер так устроен, то апдейтим
        if (res.refresh) {
          localStorage.setItem("logInJwtRefresh", res.refresh);
        }

        if (!logIn) {
          setLogIn(true);
        }
      })
      .catch((err) => {
        console.log(err);
        logout();
      });
  };

  const checkToken = useCallback(() => {
    const logInJwt = getAccessToken();
    const logInJwtRefresh = getRefreshToken();
    if (logInJwt) {
      apiAuth
        .tokenVerify(logInJwt)
        .then((response) => {
          setLogIn(true);
        })
        .catch((err) => {
          if (err.message.code === "token_not_valid" && logInJwtRefresh) {
            updateToken();
          } else {
            console.log(err);
            logout();
          }
        });
    } else {
      setLogIn(false);
    }
  }, []);

  const handleLogin = (email, password) => {
    setPreloader(true);
    return new Promise((resolve, reject) => {
      apiAuth
        .login({ email, password })
        .then((res) => {
          if (res) {
            localStorage.setItem("logInJwt", res.access);
            localStorage.setItem("logInJwtRefresh", res.refresh);
            setLogIn(true);
            setPreloader(false);
            navigate("/profile");
            resolve(res);
          }
        })
        .catch((err) => {
          setPreloader(false);
          setLogIn(false);
          reject(err);
        });
    });
  };

  const handleRegister = (
    city,
    first_name,
    last_name,
    email,
    phone,
    password,
    language
  ) => {
    setPreloader(true);
    return new Promise((resolve, reject) => {
      apiAuth
        .auth({ city, first_name, last_name, email, phone, password, language })
        .then((res) => {
          if (res !== 400) {
            setPreloader(false);
            resolve(res);
          }
        })
        .catch((err) => {
          setPreloader(false);

          if (err.message) {
            reject(err.message);
          } else {
            reject(err);
          }
        });
    });
  };

  // function handleChangeEmail(currentPassword, newEmail) {
  //   setPreloader(true);
  //   if (!logIn) {
  //     return;
  //   }

  //   return new Promise((resolve, reject) => {
  //     apiAuth
  //       .newEmailRequest({
  //         current_password: currentPassword,
  //         new_email: newEmail,
  //       })
  //       .then((res) => {
  //         resolve(res);
  //         console.log("result ok");
  //       })
  //       .catch((err) => {
  //         console.log("result isnt ok");
  //         if (err.message) {
  //           reject(err.message);
  //         } else {
  //           reject(err);
  //         }
  //       })
  //       .finally(() => {
  //         setPreloader(false);
  //       });
  //   });
  // }

  async function handleChangeEmail(currentPassword, newEmail) {
    setPreloader(true);

    if (!logIn) {
      setPreloader(false);
      throw new Error("Not logged in");
    }

    try {
      const res = await apiAuth.newEmailRequest({
        current_password: currentPassword,
        new_email: newEmail,
      });
      return res;
    } catch (err) {
      throw err.message;
    } finally {
      setPreloader(false);
    }
  }

  function handleChangePassword(currentPassword, newPassword) {
    setPreloader(true);

    if (!logIn) {
      return;
    }

    return new Promise((resolve, reject) => {
      apiAuth
        .newPasswordRequest({
          current_password: currentPassword,
          new_password: newPassword,
        })
        .then((res) => {
          // Показываем сообщение о том, что на адрес отправлен новый пароль
          resolve(res);
          setPreloader(false);
        })
        .catch((err) => {
          if (err.message) {
            reject(err.message);
          } else {
            reject(err);
          }
          setPreloader(false);
        });
    });
  }

  function handleResetPassword(uid, token, newPassword, language) {
    setPreloader(true);

    return new Promise((resolve, reject) => {
      apiAuth
        .resetPassword(uid, token, newPassword, language)
        .then((res) => {
          resolve(res);
          setPreloader(false);
        })
        .catch((err) => {
          if (err.message) {
            reject(err.message);
          } else {
            reject(err);
          }
          setPreloader(false);
        });
    });
  }

  useEffect(() => {
    if (getRefreshToken()) {
      checkToken();
    }
  }, []); // проверка наличия токена

  useEffect(() => {
    if (logIn) {
      let fiveMinutes = 1000 * 60 * 5;
      const interval = setInterval(() => {
        updateToken();
      }, fiveMinutes);
      return () => clearInterval(interval);
    }
  }, [logIn]);

  let contextData = {
    handleLogin,
    logout,
    logIn,
    handleRegister,
    isPreloader,
    handleChangeEmail,
    handleChangePassword,
    handleResetPassword,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
