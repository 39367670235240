import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MyListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";

import * as S from "./SelectPlace.styles";

export default function DialogSelect({ ...props }) {
  const [open, setOpen] = React.useState(false);
  const { selectedCity, aboutUs, handleCitySelection } = props;
  const { t } = useTranslation();

  const handleChange = (event) => {
    handleCitySelection(event.target.value || "", { id: "delivery" });

  };

  const handlePlaceChange = (event) => {
    if (event.target.value === "delivery") {

      handleCitySelection(selectedCity.city, { id: "delivery" });
    } else {
      const restaurant = aboutUs
        .filter((city) => city.city === selectedCity.city)[0]
        .restaurants.filter(
          (restaurant) => restaurant.id === event.target.value
        )[0];
 
      handleCitySelection(selectedCity.city, restaurant);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <div>
      <S.Button disableRipple className="selectPlace__button" onClick={handleClickOpen}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* <span className="selectPlace__city">
            Сменить город
          </span> */}
          {/* <span className="selectPlace__place">{place}</span> */}
        </Box>

        <KeyboardArrowDownIcon sx={{fontSize: "16px"}} />
      </S.Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle> {t("header.choseCity", "Выберете город")}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                native
                value={selectedCity.city}
                onChange={handleChange}
                inputProps={{ "arihandleChangea-label": "Without label" }}
              >
                {aboutUs.map((city) => {
                  return (
                    <option value={city.city} key={city.city}>
                      {city.city}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: 200 }} fullWidth>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={selectedCity.id}
                onChange={handlePlaceChange}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="delivery">{t("cart.delivery", "Доставка")}</MenuItem>
                <MyListSubheader>{t("header.takeAway", "Самовывоз")}:</MyListSubheader>
                {selectedCity &&
                  aboutUs
                    .filter((city) => city.city === selectedCity.city)[0]
                    .restaurants?.map((restaurant) => {
                      return (
                        <MenuItem value={restaurant?.id} key={restaurant?.id}>
                          {restaurant?.address}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
