import React, { useEffect } from "react";
import Select from "react-select";

export default function SelectAddress({
  defaultValue,
  options,
  id,
  name,
  value,
  onChange,
  isDisabled,
}) {
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: "#000000",
      backgroundColor: state.isFocused
        ? "#e4e4e4"
        : state.isSelected
        ? "#A0A0A0"
        : "#F9F9F9",
      border: "none",
    }),

    control: (defaultStyles, state) => ({
      ...defaultStyles,
      color: "#000000",
      backgroundColor: "#F9F9F9",
      border:
        state.selectProps.required && !state.isDisabled
          ? "1px solid #FF3055"
          : "1px solid #A0A0A0",
      borderRadius: "10px",
      padding: "10px",
      boxShadow: "none",
      padding: "0px",
      width: "100%",
      maxWidth: "272px",
      minWidth: "272px",
      margin: "10px 0 5px 0",
      opacity: isDisabled ? "0.5" : "1",
      "&:hover": {
        borderColor: "#A0A0A0",
      },
      "&:focus": {
        borderColor: "#00FF00",
      },
      "&:active": {
        borderColor: "#000000",
      },
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000000" }),
  };

  return (
    <Select
      defaultValue={defaultValue || ""}
      options={options}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      styles={customStyles}
      isDisabled={isDisabled}
    ></Select>
  );
}
