import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { CurrentUserProvider } from "../../contexts/CurrentUserContext";
import { FormDataProvider } from "../../contexts/FormDataContext";
import ProtectedRoute from "../ProtectedRoute";
import { AuthProvider } from "../../contexts/AuthContext";

import Header from "../Header/Header";
import HeaderBurger from "../HeaderBurger/HeaderBurger";
import HeaderMenu from "../HeaderMenu/HeaderMenu";

import Register from "../Register/Register";
import Login from "../Login/Login";
import ResetPasswordRequest from "../../utils/ResetPasswordRequest";
import ResetPasswordConfirm from "../../utils/ResetPasswordConfirm";
import ActivationPage from "../../utils/ActivationPage";
import Profile from "../Profile/Profile";
import MyOrders from "../MyOrders/MyOrders";
import MyAddress from "../MyAddress/MyAddress";
import MyCoupons from "../MyCoupons/MyCoupons";

import Dishes from "../Dishes/Dishes";
import PopupDish from "../PopupDish/PopupDish";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

import Extra from "../Extra/Extra";
import Rolls from "../Rolls/Rolls";
import Backed from "../Backed/Backed";
import Futomaki from "../Futomaki/Futomaki";
import Handrolls from "../Handrolls/Handrolls";
import Maki from "../Maki/Maki";
import Sandochi from "../Sandochi/Sandochi";
import Sushi from "../Sushi/Sushi";
import Soups from "../Soups/Soups";
import Tempura from "../Tempura/Tempura";
import Woks from "../Woks/Woks";
import Vegan from "../Vegan/Vegan";
import Sets from "../Sets/Sets";

import Cart from "../Cart/Cart";
import Delivery from "../Delivery/Delivery";
import Pickup from "../Pickup/Pickup";
import Payment from "../Payment/Payment";

import Promo from "../Promo/Promo";
import Contacts from "../Contact/Contact";
import NotFound from "../NotFound/NotFound";
import Footer from "../Footer/Footer";

import MainApi from "../../utils/MainApi";

import Preloader from "../Preloader/Preloader";
import Tooltip from "../UI/Tooltip/Tooltip";

import { I18nextProvider } from "react-i18next";
import i18n from "../../utils/i18n";

import "./App.css";

import Banner from "../Banner/Banner"; // удалить позже
import PopupRegistration from "../PopupDish/PopupRegistration";

import getUserLocation from "../../utils/Geolocation/geolocation";

function App() {
  // location
  const navigate = useNavigate();
  const location = useLocation();

  // language state
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng").toLowerCase()
  ); //начальный язык

  const [finalOrderInfo, setFinalOrderInfo] = useState({
    amount: false,
    total_discount: false,
    total_amount: false,
  });

  //const [coupons, setCoupons] = useState([]); нет на бэке

  // Promo news Items state
  const [promoNews, setPromoNews] = useState([]);

  // About us and our contacts state
  const [aboutUs, setAboutUs] = useState(null);
  const [selectedCity, setSelectedCity] = useState(""); // Общее состояние для выбранного города и ресторана/типа доставки
  const [isDiscount, setIsDiscount] = useState(false); // Проверка скидки от ресторана

  // dishes Items
  const [dishes, setDishesItems] = useState([]);
  const [actualMenu, setActualMenu] = useState([]);
  const [isMenu, setIsMenu] = useState(true);

  // Проверяем, есть ли элементы в корзине
  const [cartData, setCartData] = useState(() => {
    // Пытаемся получить данные из localStorage
    const localData = localStorage.getItem("cartDishes");
    return localData ? JSON.parse(localData) : [];
  });

  // для отрисовки блюд в корзине
  const [isLoadedFromStorage, setIsLoadedFromStorage] = useState(false);

  // состояние для delivery
  const [isDelivery, setDelivery] = useState([]);

  // состояние для takeaway
  const [isTakeaway, setTakeaway] = useState([]); // для точек магазинов
  const [isTakeawayPayment, setTakeawayPayment] = useState(false); // или true, если по умолчанию нужен самовывоз для Payment

  //состояние для промокода
  const [promoCode, setPromoCode] = useState("");
  //состояние для скидки
  const [discount, setDiscount] = useState({ type: null, amount: 0 });

  // dish Items state
  const [selectedDish, setSelectedDish] = useState({});

  // burger open state
  const [isBurger, setIsBurger] = useState(false);

  // burger header open state
  const [burgerHeader, setBurgerHeader] = useState(false);

  // errors state
  const [errorMessage, setErrorMessage] = useState("");

  // preloader state
  const [isPreloader, setPreloader] = useState(false);

  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const [isInfoTooltipMessage, setIsInfoTooltipMessage] = useState({
    image: "",
    caption: "",
  });

  const [isPopup, setIsPopup] = useState(false);

  const [timeDelivery, setTimeDelivery] = useState([]);

  // functionality -- update adress - пока не нужно
  /*function handleChangeAdress(address) {
    setPreloader(true);
    MainApi.changeAdress({ address })
    .then((res) => {
        setCurrentUser(res);
        setPreloader(false);
    }).catch(err => {
        if (err === 'Ошибка: 409') {
            setErrorMessage(CONFLICT_REG);
        } else {
            setErrorMessage(ERR_REGISTER);
        }
        console.log(err);
        setPreloader(false);
    });
  }*/
  //end

  // functionality -- getting news from Api
  function getNews() {
    setPreloader(true);
    MainApi.getPromoNews()
      .then((promoNews) => {
        setPromoNews(promoNews);
        setPreloader(false);
      })
      .catch((err) => {
        setPreloader(false);
      });
  }
  //end

  // functionality -- getting news from Api
  function getAboutUsFunction() {
    setPreloader(true);
    MainApi.getOurContacts()
      .then((data) => {
        const about = data.filter((item, index) => index !== data.length - 1);
        setAboutUs(about);
        if (
          data &&
          data[0] &&
          data[0].restaurants &&
          data[0].restaurants.length > 0
        ) {
          // Устанавливаем начальный выбранный город как первый город из загруженных данных
          // setSelectedCity(data[0].restaurants[0].city);
          setIsDiscount(data[data.length - 1]?.cash_discount || false);
        }
        setPreloader(false);
      })
      .catch((err) => {
        console.error(err);
        setPreloader(false);
      });
  }

  useEffect(() => {
    if (
      aboutUs &&
      aboutUs.length > 0 &&
      isDelivery.length > 0 &&
      !selectedCity
    ) {
      // Пример, где aboutUs - массив объектов, каждый из которых представляет отдельный город
      const cityFromStorage = localStorage.getItem("selectedCity");
      if (cityFromStorage && isDelivery) {
        const cityData = JSON.parse(cityFromStorage);
        const cityName = cityData.city;
        const idRestaraunt = cityData.id;
        let restaurant;
        if (idRestaraunt === "delivery") {
          const cityOfDelivery = isDelivery.filter(
            (item) => item.city === cityName
          );
          setTimeDelivery([
            cityOfDelivery[0]?.min_time,
            cityOfDelivery[0]?.max_time,
          ]);
          restaurant = {
            id: "delivery",
            open_time: cityOfDelivery[0]?.min_time,
            close_time: cityOfDelivery[0]?.max_time,
            phone: cityOfDelivery[0]?.phone,
          };
        } else {
          restaurant = aboutUs
            .map((cityData) => cityData.restaurants)
            .flat()
            .find((item) => item.id === idRestaraunt);
        }
        setSelectedCity({ city: cityName, ...restaurant });
      } else {
        const initialCity =
          aboutUs.find((cityData) => cityData.city === "Beograd") || aboutUs[0];
        const restaurant = initialCity.restaurants[0];
        setSelectedCity({ city: initialCity.city, ...restaurant });
      }
    }
  }, [aboutUs, setSelectedCity, selectedCity, isDelivery]);

  useEffect(() => {
    if (!dishes) return;
    if (selectedCity && selectedCity.id === "delivery" && isDelivery) {
      const articles = isDelivery?.filter(
        (item) => item.city === selectedCity.city
      )[0]?.dishes;
      const newMenu = dishes?.filter((dish) =>
        articles?.includes(dish.article)
      );
      if (articles?.length < 1) {
        setIsMenu(false);
      } else {
        setActualMenu(newMenu);
        setIsMenu(true);
      }
    } else if (selectedCity && isTakeaway && isTakeaway.length > 0) {
      const cityInfo = isTakeaway?.filter(
        (item) => item.city === selectedCity.city
      )[0]?.restaurants;
      const articles = cityInfo?.filter(
        (item) => item.id === selectedCity.id
      )[0]?.dishes;
      const newMenu = dishes.filter((dish) => articles?.includes(dish.article));
      if (articles?.length < 1) {
        setIsMenu(false);
      } else {
        setActualMenu(newMenu);
        setIsMenu(true);
      }
      // setActualMenu(newMenu);
    }
  }, [isTakeaway, selectedCity, isDelivery, dishes]);

  // Функция обработчика выбора города
  const handleCitySelection = (city, restaurant) => {
    setSelectedCity(() => ({ city: city, ...restaurant }));
    if (restaurant.id === "delivery") {
      const delivery = localStorage.getItem("delivery");
      const deliveryData = JSON.parse(delivery);
      if (!deliveryData) return;
      const cityOfDelivery = deliveryData.filter((item) => item.city === city);
      setTimeDelivery([
        cityOfDelivery[0]?.min_time,
        cityOfDelivery[0]?.max_time,
      ]);
      setSelectedCity(() => ({
        city: city,
        ...restaurant,
        open_time: cityOfDelivery[0]?.min_time,
        close_time: cityOfDelivery[0]?.max_time,
      }));
    } else {
      setSelectedCity(() => ({ city: city, ...restaurant }));
    }
    localStorage.setItem(
      "selectedCity",
      JSON.stringify({ city, ...restaurant })
    );
  };
  //end

  // functionality -- getting dishes from Api
  function getDishes() {
    setPreloader(true);
    MainApi.getDishesFromApi()
      .then((dishes) => {
        setDishesItems(dishes);
        localStorage.setItem("dishes", JSON.stringify(dishes));
        setPreloader(false);
      })
      .catch((err) => {
        setPreloader(false);
      });
  }
  //end

  // functionality -- dishes for cart
  function getDishForCart() {
    setPreloader(true);
    // Возвращаем промис, чтобы его можно было использовать в useEffect
    return MainApi.getCartData()
      .then((cartResponse) => {
        if (!cartResponse) {
          // проверка на null, который может быть возвращен при HTTP 204
          return []; // корзина пуста, возвращаем пустой массив
        }
        // Если cartResponse - это массив с объектом, содержащим поле cartdishes
        const cartItems = cartResponse[0]?.cartdishes || [];
        setCartData(cartItems);
        localStorage.setItem("cartDishes", JSON.stringify(cartItems));
        setPreloader(false);
        return cartItems; // Возвращаем результат
      })
      .catch((err) => {
        setPreloader(false);
        throw err; // Возвращаем ошибку в промис, чтобы она была доступна в .catch()
      });
  }

  // Функция для загрузки корзины при первом входе или при ошибке
  function fetchDataAgain() {
    getDishForCart()
      .then((cartItems) => {
        if (cartItems) {
          // Обработка полученных данных, если они есть (не null)
          if (cartItems.length > 0) {
            setCartData(cartItems);
            localStorage.setItem("cartDishes", JSON.stringify(cartItems));
          } else {
            // Если массив пустой
            setCartData([]); // Здесь можно не записывать в localStorage, т.к. корзина пуста
          }
        } else {
          // cartItems равно null, значит сервер вернул ответ 204 No Content, корзина пуста
          setCartData([]);
        }
      })
      .catch((error) => {
        console.error("Error during initial fetch of cartDishes:", error);
        setCartData([]); // В случае ошибки также устанавливаем пустую корзину
      });
  }

  useEffect(() => {
    const storageData = localStorage.getItem("cartDishes");
    if (storageData) {
      try {
        const storedCartData = JSON.parse(storageData);
        setCartData(storedCartData);
        setIsLoadedFromStorage(true);
      } catch (error) {
        console.error("Error parsing cartDishes from localStorage:", error);
        // Если произошла ошибка в процессе разбора данных из localStorage,
        // возможно, они повреждены или некорректны - очищаем localStorage от cartDishes
        localStorage.removeItem("cartDishes");
        // И далее загружаем корзину заново, как будто пользователь зашел первый раз
        fetchDataAgain();
      }
    } else {
      // Та же функция, что и при ошибке разбора, для повторной попытки загрузки данных
      fetchDataAgain();
    }

    getUserLocation()
      .then((res) => {
        if (res && res === "Novi Sad") {
          handleCitySelection("NoviSad", { id: "delivery" });
        } else if (res && res === "Beograd") {
          handleCitySelection("Beograd", { id: "delivery" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []); // Зависимости для useEffect пусты, так что он выполнится один раз при монтировании компонента

  // Функция для удаления всех блюд из корзины
  const handleClearCart = () => {
    MainApi.deleteAllDishes()
      .then(() => {
        setCartData([]); // очищаем корзину на клиенте
        localStorage.removeItem("cartDishes"); // удаляем данные о корзине из `localStorage`
        // Можно также установить пустой массив, используя localStorage.setItem('cartDishes', JSON.stringify([]));
      })
      .catch((error) => {
        console.error("Ошибка при очистке корзины: ", error);
      });
  };

  // добавление в корзину
  const handleAddToCart = (newItem) => {
    setCartData((prevItems) => {
      if (!Array.isArray(prevItems)) {
        console.error("prevItems is not an array", prevItems);
        prevItems = [];
      }
      // Здесь мы генерируем уникальный ID для cartDish
      const generateUniqueId = () => {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
      };
      const isItemAlreadyInCart = prevItems.some(
        (cartItem) => cartItem.dish.article === newItem.article
      );
      const updatedCart = !isItemAlreadyInCart
        ? [...prevItems, { id: generateUniqueId(), dish: newItem, quantity: 1 }]
        : prevItems.map((cartItem) =>
            cartItem.dish.article === newItem.article
              ? { ...cartItem, quantity: cartItem.quantity + 1 }
              : cartItem
          );
      /*const updatedCart = !isItemAlreadyInCart
          ? [...prevItems, newItem]
          : prevItems.map(cartItem =>
              cartItem.article === newItem.article
              ? { ...cartItem, quantity: cartItem.quantity + newItem.quantity }
              : cartItem
          );*/
      // Сохраняем обновлённую корзину в localStorage
      localStorage.setItem("cartDishes", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const handleSubmitPromo = () => {
    MainApi.postPromoMethod({ promocode: promoCode })
      // Считаем, что data в формате { promocode_disc: "10.00", promocode_type: "%" или "number" }
      .then((data) => {
        if (
          typeof data !== "object" ||
          data === null ||
          !("promocode_disc" in data)
        ) {
          throw new Error("Invalid cart data format");
        }
        const discountType =
          data.promocode_disc.slice(-1) === "%" ? "%" : "number";
        const discountAmount = parseFloat(data.promocode_disc.replace("%", ""));
        setDiscount({ type: discountType, amount: discountAmount });
        setErrorMessage(null);
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage("errors.promo_notfound");
      });
  };
  // end

  // functionality -- delivery
  function getDelivery() {
    setPreloader(true);
    MainApi.getDeliveryMethod()
      .then((delivery) => {
        setDelivery(delivery);
        localStorage.setItem("delivery", JSON.stringify(delivery));
        setTimeDelivery([delivery[0].min_time, delivery[0].max_time]);
        setPreloader(false);
      })
      .catch((err) => {
        setPreloader(false);
      });
  }

  function handleSubmitOrderData(isError) {
    if (isError) {
      setIsPopup("errors.other_errors");
    } else {
      setIsPopup("delivery.order_successful");
      setCartData([]);
      setPromoCode("");
      setFinalOrderInfo({
        amount: false,
        total_discount: false,
        total_amount: false,
        promocode: null,
      });
    }
  }
  //end

  // functionality -- takeaway
  function getTakeaway() {
    setPreloader(true);
    MainApi.getTakeawayMethod()
      .then((takeaway) => {
        setTakeaway(takeaway);
        localStorage.setItem("takeaway", JSON.stringify(takeaway));
        setPreloader(false);
      })
      .catch((err) => {
        setPreloader(false);
      });
  }

  //эффект для остлеживания локации для Payment
  useEffect(() => {
    if (location.pathname.includes("/takeaway")) {
      setTakeawayPayment(true);
    } else if (location.pathname.includes("/delivery")) {
      setTakeawayPayment(false);
    }
  }, [location]);
  //end

  // Проверяю, выполнял ли пользователь вход ранее
  // useEffect(() => {
  //   handleTokenCheck();
  // }, [handleTokenCheck]);
  //end

  // useEffect ошибки
  useEffect(() => {
    setErrorMessage("");
  }, [setErrorMessage]);
  //end

  // отрисовка меню, новостей
  useEffect(() => {
    getDishes();
    getNews();
    getAboutUsFunction();
    getDelivery();
    getTakeaway();
  }, []); // Пустой массив зависимостей, чтобы запрос выполнился один раз
  //end

  // functionality -- burger
  function handleBurgerMenu() {
    setIsBurger(!isBurger);
  }

  function handleBurgerHeader() {
    setBurgerHeader(!burgerHeader);
  }
  //end

  // popup dish
  function handleDishClick(dish) {
    setSelectedDish(dish);
  }
  //end

  function closePopup() {
    setSelectedDish({});
    setIsBurger(false);
    setBurgerHeader(false);
    setIsTooltipActive(false);
  }
  //end

  // Функция закрытия окна на оверлей
  useEffect(() => {
    function handleOverlayClick(evt) {
      if (
        evt.target.classList.contains("header-burger-menu") ||
        evt.target.classList.contains("burger-menu") ||
        evt.target.classList.contains("popup")
      ) {
        closePopup();
      }
    }
    document.addEventListener("mousedown", handleOverlayClick);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
    // eslint-disable-next-line
  }, []);
  //end

  // Функция закрытия окон по esc
  useEffect(() => {
    const closeByEsc = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        closePopup();
      }
    };
    document.addEventListener("keydown", closeByEsc);
    return () => document.removeEventListener("keydown", closeByEsc);
    // eslint-disable-next-line
  }, []);
  // end

  return (
    <>
      <AuthProvider>
        <I18nextProvider i18n={i18n}>
          {/* <CurrentUserProvider></CurrentUserProvider> */}
          {/* <CurrentUserContext.Provider value={currentUser}> */}
          <FormDataProvider>
            <CurrentUserProvider>
              <BurgerMenu
                isBurger={isBurger}
                handleBurgerMenu={handleBurgerMenu}
                dishes={actualMenu}
                language={language}
              />

              <HeaderBurger
                burgerHeader={burgerHeader}
                handleBurgerHeader={handleBurgerHeader}
                language={language}
                onLanguageChange={setLanguage}
                aboutUs={aboutUs}
                onCitySelected={handleCitySelection}
                selectedCity={selectedCity}
              />

              <HeaderMenu
                handleBurgerHeader={handleBurgerHeader}
                cartData={cartData}
                selectedCity={selectedCity}
                aboutUs={aboutUs}
                onCitySelected={handleCitySelection}
              />

              <Header
                language={language}
                onLanguageChange={setLanguage}
                cartData={cartData}
                aboutUs={aboutUs}
                onCitySelected={handleCitySelection}
                selectedCity={selectedCity}
              />

              {process.env.REACT_APP_ENVIRONMENT === "test" && <Banner />}

              <Preloader isPreloader={isPreloader} />

              <Routes>
                <Route
                  path="/registration"
                  element={
                    <Register
                      // onRegister={handleRegister}
                      // errorMessage={errorMessage}
                      aboutUs={aboutUs}
                      language={language}
                    />
                  }
                />

                <Route
                  path="/reset-password"
                  element={<ResetPasswordRequest />}
                />

                <Route
                  path="/reset_password_confirm/:uid/:token"
                  element={<ResetPasswordConfirm language={language} />}
                />

                <Route
                  path="/activation/:uid/:token"
                  element={<ActivationPage />}
                  language={language}
                />

                <Route path="/login" element={<Login language={language} />} />

                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <Profile
                        errorMessage={errorMessage}
                        handleBurgerHeader={handleBurgerHeader}
                        setFinalOrderInfo={setFinalOrderInfo}
                        handlePromo={setPromoCode}
                        language={language}
                        selectedCity={selectedCity}
                        aboutUs={aboutUs}
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/profile/orders"
                  element={
                    <ProtectedRoute>
                      <MyOrders
                        // orders={orders}
                        language={language}
                        onAddToCart={handleAddToCart}
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/profile/adresses"
                  element={
                    <ProtectedRoute>
                      <MyAddress
                        isDelivery={isDelivery}
                        selectedCity={selectedCity}
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/profile/coupons"
                  element={
                    <ProtectedRoute>
                      <MyCoupons />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/"
                  element={
                    <Dishes
                      selectedDish={selectedDish}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      dishes={actualMenu}
                      isPreloader={isPreloader}
                      handleBurgerHeader={handleBurgerHeader}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/extra"
                  element={
                    <Extra
                      dishes={actualMenu}
                      language={language}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/rolls"
                  element={
                    <Rolls
                      dishes={actualMenu}
                      language={language}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/futomaki"
                  element={
                    <Futomaki
                      dishes={actualMenu}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/backed"
                  element={
                    <Backed
                      dishes={actualMenu}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/tempura"
                  element={
                    <Tempura
                      dishes={actualMenu}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/sandochi"
                  element={
                    <Sandochi
                      dishes={actualMenu}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/handrolls"
                  element={
                    <Handrolls
                      dishes={actualMenu}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/maki"
                  element={
                    <Maki
                      dishes={actualMenu}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/sushi"
                  element={
                    <Sushi
                      dishes={actualMenu}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/sets"
                  element={
                    <Sets
                      dishes={actualMenu}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/woks"
                  element={
                    <Woks
                      dishes={actualMenu}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/soups"
                  element={
                    <Soups
                      dishes={actualMenu}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/vegan"
                  element={
                    <Vegan
                      dishes={actualMenu}
                      onDishClick={handleDishClick}
                      handleBurgerMenu={handleBurgerMenu}
                      language={language}
                      onAddToCart={handleAddToCart}
                      cartData={cartData}
                      isMenu={isMenu}
                    />
                  }
                />

                <Route
                  path="/cart"
                  element={
                    <Cart
                      dishes={actualMenu}
                      cartData={cartData}
                      setCartData={setCartData}
                      language={language}
                      onAddToCart={handleAddToCart}
                      extraDishes={actualMenu.filter((dish) =>
                        dish.category.some((cat) => cat.slug === "extra")
                      )}
                      promoCode={promoCode}
                      setPromoCode={setPromoCode}
                      handleSubmitPromo={handleSubmitPromo}
                      errorMessage={errorMessage}
                      onClearCart={handleClearCart}
                      discount={discount}
                    />
                  }
                />

                <Route
                  path="/delivery"
                  element={
                    <Delivery
                      isDelivery={isDelivery}
                      cartData={cartData}
                      // onSubmitDeliveryData={handleSubmitDeliveryData}
                      selectedCity={selectedCity}
                      setFinalOrder={setFinalOrderInfo}
                      setPromoCode={setPromoCode}
                      promoCode={promoCode}
                      language={language}
                      finalOrder={finalOrderInfo}
                      isDiscount={isDiscount}
                      timeDelivery={timeDelivery}
                      aboutUs={aboutUs}
                      handleCitySelection={handleCitySelection}
                    />
                  }
                />

                <Route
                  path="/takeaway"
                  element={
                    <Pickup
                      cartData={cartData}
                      isTakeaway={isTakeaway}
                      errorMessage={errorMessage}
                      handleSubmitPromo={handleSubmitPromo}
                      promoCode={promoCode}
                      setPromoCode={setPromoCode}
                      selectedCity={selectedCity}
                      setFinalOrder={setFinalOrderInfo}
                      finalOrder={finalOrderInfo}
                      language={language}
                      isDiscount={isDiscount}
                      handleCitySelection={handleCitySelection}
                    />
                  }
                />

                <Route
                  path="/payment"
                  element={
                    <Payment
                      onSubmitOrderData={handleSubmitOrderData}
                      cartData={cartData}
                      isTakeawayPayment={isTakeawayPayment}
                      finalOrder={finalOrderInfo}
                      language={language}
                      selectedCity={selectedCity}
                      timeDelivery={timeDelivery}
                    />
                  }
                />

                <Route
                  path="/contacts"
                  element={
                    <Contacts
                      aboutUs={aboutUs}
                      language={language}
                      onCitySelected={handleCitySelection}
                    />
                  }
                />

                <Route
                  path="/promo"
                  element={<Promo promoNews={promoNews} language={language} />}
                />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </CurrentUserProvider>

            <PopupDish
              dish={selectedDish}
              onClose={closePopup}
              language={language}
              onAddToCart={handleAddToCart}
              cartData={cartData}
            />

            <Tooltip
              isTooltipActive={isTooltipActive}
              //onOpenMenu={handleOpenMenu}
              onClose={closePopup}
              caption={isInfoTooltipMessage.caption}
              image={isInfoTooltipMessage.image}
            />

            {isPopup && (
              <PopupRegistration
                setIsPopup={setIsPopup}
                message={isPopup}
              ></PopupRegistration>
            )}

            <Footer />
          </FormDataProvider>
          {/* </CurrentUserContext.Provider> */}
        </I18nextProvider>
      </AuthProvider>
    </>
  );
}

export default App;
