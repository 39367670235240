import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { AuthContext } from "../../contexts/AuthContext";
import useFormValidation from "../../utils/FormValidation";
import { useFormData } from "../../contexts/FormDataContext";
import { useTranslation } from "react-i18next";
import DishError from "../Errors/DishError";
import MainApi from "../../utils/MainApi";
import AddressAutocomplete from "../../utils/AddressAutocomplete";
import Promocode from "../Promocode/Promocode";
import { prepareAddress, splitComment } from "../../utils/address";
import SelectAddress from "../Select/SelectAddress";
import Tag from "../UI/Tag/Tag";
import PopupRegistration from "../PopupDish/PopupRegistration";
import "./Delivery.css";
import { useUtensilsCounter } from "../../hooks/useUtensils";
import DatePicker from "../UI/DatePicker/DatePicker";
import {
  getMonthNames,
  isAsSoonAsPossible,
} from "../../utils/DateUtils/dateUtils";

function Delivery({
  isDelivery,
  cartData,
  selectedCity,
  setFinalOrder,
  setPromoCode,
  promoCode,
  language,
  finalOrder,
  isDiscount,
  timeDelivery,
  handleCitySelection,
}) {
  const [loading, setLoading] = useState(false);
  //  хук useFormData для доступа к состоянию и функции обновления
  const { updateFormData } = useFormData();

  // location
  const navigate = useNavigate();

  const { t } = useTranslation();

  //состояние кнопки radio
  const [isChecked, setIsChecked] = useState(false);
  const [isFullAddress, setIsFullAddress] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isNewAddress, setIsNewAddress] = useState(false);

  //каунтер до 10 приборов
  // const [persons_qty, setCount] = useState(0);

  // выбранное время
  const [selectedMonth, setSelectedMonth] = useState("Как можно быстрее");

  //если залогинился юзер - вписать автоматически его данные
  const currentUser = useContext(CurrentUserContext);
  const {
    values,
    isValid,
    errors,
    setErrors,
    setValues,
    formRef,
    handleChange,
    handleInput,
    checkFormValidity,
  } = useFormValidation();

  const { logIn } = useContext(AuthContext);
  const [isWrongTime, setIsWrongTime] = useState(false);

  const [persons_qty, handleUtensils, handleAdd, handleDelete] =
    useUtensilsCounter(cartData);

  useEffect(() => {
    if (cartData.length === 0) {
      navigate("/cart");
    }

    if (finalOrder.recipient_address && !finalOrder.my_delivery_address) {
      setIsNewAddress(true);
    }

    handleUtensils(cartData);
  }, []);

  const setWrongTime = () => {
    setIsWrongTime(true);
  };

  // Функция для вывода дня и месяца
  const MONTH_NAMES = getMonthNames(t);

  useEffect(() => {
    const addressData = finalOrder.comment
      ? splitComment(finalOrder.comment)
      : splitComment(null);
    if (finalOrder.recipient_address && finalOrder.my_delivery_address) {
      setSelectedAddress({
        name: finalOrder.recipient_address,
        value: finalOrder.my_delivery_address,
        label: finalOrder.recipient_address,
      });
    }

    setValues((v) => ({
      ...v,
      recipient_name:
        finalOrder.recipient_name || currentUser.currentUser?.first_name || "",
      recipient_phone:
        finalOrder.recipient_phone || currentUser.currentUser?.phone || "",
      recipient_address: finalOrder.recipient_address || "",
      PrivateHome: finalOrder.private_home || "",
      home: finalOrder.home || "",
      flat: addressData.flat,
      floor: addressData.floor,
      homephone: addressData.homephone,
      coordinates: finalOrder.coordinates || "",
      comment: addressData.comment,
      persons_qty,
      delivery_time: "11:00",
      my_delivery_address: finalOrder.my_delivery_address || "",
      payment_type: finalOrder.payment_type || "",
    }));
    // Устанавливаем начальные значения для формы, используя информацию о текущем пользователе
    if (currentUser && Array.isArray(currentUser.addresses)) {
      // const lastAddress =
      //   currentUser.addresses.length > 0
      //     ? currentUser.addresses[currentUser.addresses.length - 1].address
      //     : "";

      setSuggestions(
        currentUser.addresses?.map((addr) => ({
          ...addr,
          name: addr.address, // создаем новое свойство recipient_address равное address
          value: addr.id,
          label: addr.address,
        }))
      );
    }

    checkFormValidity();
  }, [currentUser]);

  useEffect(() => {
    if (isDelivery) {
      setCityOptions(
        isDelivery.map((city) => ({
          value: city.city,
          label: city.city,
          name: city.city,
          ...city,
        }))
      );
    }
  }, [isDelivery]);

  // нажим и отжим radio btn
  const handleCheckboxChange = (e) => {
    // Только переключает состояние, если уже было выбрано
    if (e.target.name === "address") {
      if (!isNewAddress) {
        setSelectedAddress(null);
        setValues((prev) => ({
          ...prev,
          recipient_address: "",
          flat: "",
          floor: "",
          comment: "",
          coordinates: "",
          my_delivery_address: "",
          homephone: "",
          private_home: "",
        }));
      }
      setIsNewAddress((current) => !current);
    } else if (e.target.name === "togglRadio") {
      // if (isChecked) {
      setIsChecked((current) => !current);
      // }
    }
  };

  const handleUpdateAddress = (place) => {
    if (!place) return;
    let addressValue;
    let isValidAddress = false;
    if (place.address_components) {
      // Такой же подход, как и ранее, если place - это объект с компонентами адреса
      addressValue = place.formatted_address;
      const addressComponents = place.address_components;
      isValidAddress =
        addressComponents.some((component) =>
          component.types.includes("country")
        ) &&
        addressComponents.some((component) =>
          component.types.includes("route")
        );
      setValues((prevState) => ({
        ...prevState,
        coordinates: `${place.geometry.location.lat()}, ${place.geometry.location.lng()}`,
        recipient_address: addressValue, // используем новый ключ для адреса
        isAddressValid: isValidAddress,
        my_delivery_address: null,
      }));
    } else {
      // Если place - это сохраненный адрес, копируем его значение
      addressValue = place.address;
      setValues((prevState) => ({
        ...prevState,
        coordinates: place.coordinates,
        recipient_address: addressValue, // используем новый ключ для адреса
        isAddressValid: isValidAddress,
        // private_home: values.private_home,
        // home: values.home,
        flat: place.flat,
        floor: place.floor,
        homephone: place.interfon,
        my_delivery_address: place.id,
      }));
    }
    checkFormValidity();
  };

  const handlePromoChange = (code) => {
    setPromoCode(code);
  };

  const handleDeliveryTime = () => {
    let deliveryTime = null; // Для "Как можно быстрее" отправляем null
    // Проверяем, выбрана ли конкретная дата и время
    if (!isAsSoonAsPossible(selectedMonth) && values.delivery_time) {
      const [day, monthName] = selectedMonth.split(" ");
      const monthNumber = MONTH_NAMES.indexOf(monthName) + 1; // Получаем номер месяца
      const formattedMonth = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
      const year = new Date().getFullYear();
      deliveryTime = `${day}.${formattedMonth}.${year} ${values.delivery_time}`;
    } else {
      deliveryTime = null;
    }
    return deliveryTime;
  };

  const handleDishesForOrder = () => {
    const dishesToOrder = cartData.map((dish) => {
      const article = dish.dish.article;
      const { quantity } = dish;
      return { dish: article, quantity: quantity };
    });

    return dishesToOrder;
  };

  useEffect(() => {
    if (values.recipient_address) {
      const getAddress = prepareAddress(
        values.recipient_address,
        values.private_home,
        values.home,
        values.flat,
        values.floor,
        values.homephone
      );

      if (!getAddress.isHome) {
        setIsFullAddress(false);
        setErrors((prev) => ({
          ...prev,
          ["address"]: "addresses.enter_your_full_address",
        }));
      } else {
        setErrors((prev) => ({ ...prev, ["address"]: "" }));
        setIsFullAddress(true);
      }
    }
  }, [values.recipient_address]);

  const handleOrderData = (promo = null) => {
    const getAddress = prepareAddress(
      values.recipient_address,
      values.private_home,
      values.home,
      values.flat,
      values.floor,
      values.homephone
    );

    if (!getAddress.isHome) {
      setIsFullAddress(false);
      setErrors((prev) => ({
        ...prev,
        ["address"]: "addresses.enter_your_full_address",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        ["address"]: "",
      }));
      setIsFullAddress(true);
    }

    const formValues = {
      discounted_amount: values.discounted_amount,
      items_qty: values.items_qty,
      recipient_name: values.recipient_name,
      recipient_phone: values.recipient_phone,
      city: selectedCity.city,
      delivery_time: handleDeliveryTime(), // Используем составленное или null значение,
      comment:
        getAddress?.commentWithAddress + " comment from user:" + values.comment,
      persons_qty: persons_qty,
      amount: values.amount,
      promocode: promo,
      recipient_address: values.recipient_address,
      coordinates: values.coordinates,
      my_delivery_address: values.my_delivery_address,
      payment_type: values.payment_type,
      language: language,
    };

    const dishes = handleDishesForOrder();

    const orderFormData = {
      ...formValues,
      orderdishes: dishes, // уже сформированный очищенный массив блюд
    };

    updateFormData(orderFormData);

    return orderFormData;
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const orderData = handleOrderData(promoCode);
    setLoading(true);
    try {
      const res = await MainApi.postDeliveryPreChecoutMethod(
        orderData,
        language
      );
      setFinalOrder({
        ...orderData,
        discounted_amount: res.total_discount,
        amount: res.amount,
        total: res.total.total_amount,
        delivery_cost: res.delivery_cost,
      });
      setLoading(false);
      navigate("/payment");
    } catch (err) {
      if (err.message === "Ошибка: 500") {
        setErrors(() => ({
          server: true,
        }));
      } else if (err.message.delivery_time) {
        setWrongTime();
      } else {
        setErrors(() => ({
          ...err.message,
        }));
      }

      setLoading(false);
      // throw Error(JSON.stringify(err));
    }
  }

  const checkPromo = async (e, userPromo) => {
    e.preventDefault();
    const checkData = handleOrderData(userPromo);
    if (!checkData) return { data: "empty" };

    try {
      const res = await MainApi.postDeliveryPreChecoutMethod(
        checkData,
        language
      );
      if (!res) return null;

      setFinalOrder({
        ...checkData,
        discounted_amount: res.total_discount,
        amount: res.amount,
        total: res.total.total_amount,
      });
      return res;
    } catch (err) {
      if (err.message === "Ошибка: 500") {
        setErrors(() => ({
          server: true,
        }));
      } else {
        setErrors(() => ({
          ...err.message,
        }));
      }
      return { error: err.message };
    }
  };

  const deleteError = () => {
    setErrors(() => ({ ["address"]: "" }));
  };

  useEffect(() => {
    checkFormValidity();
  }, [isChecked]);

  const handleSavedAddressChange = (e) => {
    setSelectedAddress(e);
    handleUpdateAddress(e);
  };

  const handleChangeCity = (e) => {
    if (selectedCity.city === e.value) return;
    handleCitySelection(e.value, { id: "delivery" });
  };

  return (
    <>
      <div className="delivery">
        <form ref={formRef} className="delivery__form" onSubmit={handleSubmit}>
          <div className="delivery__description">
            <label className="delivery__label" htmlFor="recipient_name">
              {t("delivery.your_name", "Ваше имя")}
              <input
                value={values.recipient_name || ""}
                onChange={handleChange}
                id="recipient_name"
                className={`delivery__input ${
                  !values.recipient_name && "delivery__input--red"
                }`}
                name="recipient_name"
                type="text"
                placeholder={t("delivery.name", "Имя")}
                minLength="2"
                maxLength="400"
                // pattern="^[A-Za-zА-Яа-яЁё]{2,150}$"
                pattern="^[A-Za-zА-Яа-яЂ-џЈ-џђ-џљњћџČčĆćDždžĐđLjljNjnjŠšŽž0-9\s]+$"
                required
              />
              <span
                className={`${
                  errors.recipient_name ? "login__error" : "login__error_hidden"
                }`}
              >
                {t("delivery.field_required", "Поле обязательно для ввода")}
              </span>
            </label>
          </div>
          <div className="delivery__description">
            <label className="delivery__label" htmlFor="recipient_phone">
              {t("delivery.your_phone", "Ваш телефон")}
              <input
                value={values.recipient_phone || ""}
                onChange={handleChange}
                id="recipient_phone"
                className={`delivery__input ${
                  !values.recipient_phone && "delivery__input--red"
                }`}
                name="recipient_phone"
                type="tel"
                placeholder="+"
                minLength="11"
                maxLength="128"
                pattern="^\+[0-9]{11,128}$"
                required
              />
              <span
                className={`${
                  errors.recipient_phone
                    ? "login__error"
                    : "login__error_hidden"
                }`}
              >
                {errors.recipient_phone === "Enter a valid phone number."
                  ? t("errors.phone_invalid", "Введите корректный номер")
                  : t("delivery.field_required", "Поле обязательно для ввода")}
              </span>
            </label>
          </div>
          <div className="delivery__description">
            <label className="delivery__label" htmlFor="city">
              {t("header.choseCity", "Город")}
              <SelectAddress
                defaultValue={""}
                options={cityOptions}
                id="city"
                name="city"
                value={{
                  value: selectedCity.city,
                  label: selectedCity.city,
                  name: selectedCity.city,
                }}
                onChange={handleChangeCity}
                // isDisabled={isNewAddress}
                required
              ></SelectAddress>
            </label>
          </div>
          {logIn && (
            <>
              <div className="delivery__description">
                <label className="delivery__label" htmlFor="recipient_address">
                  {t(
                    "delivery.delivery_address",
                    "Выберите один из ваших адресов доставки"
                  )}

                  <SelectAddress
                    defaultValue={""}
                    options={suggestions}
                    id="recipient_address"
                    name="recipient_address"
                    value={selectedAddress}
                    onChange={handleSavedAddressChange}
                    isDisabled={isNewAddress}
                    required={!isNewAddress}
                  ></SelectAddress>

                  <a href="/profile/adresses" className="delivery__link">
                    {t(
                      "delivery.delivery_addnew_address",
                      "Добавить адрес в личном кабинете"
                    )}
                  </a>
                </label>
              </div>

              <div className="delivery__description">
                <input
                  id="newAddress"
                  className="delivery__input-radio"
                  type="checkbox"
                  name="address"
                  checked={isNewAddress}
                  onChange={handleCheckboxChange}
                  // onClick={handleRadioClick}
                />
                <label
                  className="delivery__label"
                  // onClick={handleRadioClick}
                  htmlFor="newAddress"
                >
                  {t("delivery.another", "Доставка на другой адрес")}
                </label>
              </div>
            </>
          )}

          {(isNewAddress || !logIn) && (
            <AddressAutocomplete
              inputClassName="delivery__input"
              updateAddress={handleUpdateAddress}
              values={values}
              handleChange={handleChange}
              addresses={currentUser.addresses || ""}
              handleInput={handleInput}
              deleteError={deleteError}
              error={errors.address}
              selectedCity={selectedCity}
              //readOnly={values.isAddressValid}
            />
          )}

          <div className="delivery__description">
            <input
              id="rd"
              className="delivery__input-radio"
              type="checkbox"
              name="togglRadio"
              checked={isChecked}
              onChange={handleCheckboxChange}
              // onClick={handleRadioClick}
            />
            <label
              className="delivery__label"
              // onClick={handleRadioClick}
              htmlFor="rd"
            >
              {t("delivery.private_house", "Частный дом")}
            </label>
          </div>
          {/* {isChecked && (
            <div className="delivery__description">
              <label className="delivery__label" htmlFor="private_home">
                {t("delivery.house", "Дом")}
                <input
                  value={values.private_home || ""}
                  onChange={handleChange}
                  id="private_home"
                  className="delivery__input"
                  name="private_home"
                  type="text"
                  placeholder={t("delivery.house", "Дом")}
                  minLength="1"
                  maxLength="1000"
                  required
                />
                <span
                  className={`${
                    errors.private_home ? "login__error" : "login__error_hidden"
                  }`}
                >
                  {t("delivery.field_required", "Поле обязательно для ввода")}
                </span>
              </label>
            </div>
          )} */}
          {!isChecked && (
            <>
              {/* <div className="delivery__description">
                <label className="delivery__label" htmlFor="home">
                  {t("delivery.house", "Дом")}
                  <input
                    value={values.home || ""}
                    onChange={handleChange}
                    id="home"
                    className="delivery__input"
                    name="home"
                    type="text"
                    placeholder={t("delivery.house", "Дом")}
                    minLength="1"
                    maxLength="1000"
                    required
                  />
                  <span
                    className={`${
                      errors.home ? "login__error" : "login__error_hidden"
                    }`}
                  >
                    {t("delivery.field_required", "Поле обязательно для ввода")}
                  </span>
                </label>
              </div> */}
              <div className="delivery__description">
                <label className="delivery__label" htmlFor="flat">
                  {t("delivery.apartment", "Квартира")}
                  <input
                    value={values.flat || ""}
                    onChange={handleChange}
                    id="flat"
                    className={`delivery__input ${
                      !values.flat && "delivery__input--red"
                    }`}
                    name="flat"
                    type="text"
                    placeholder={t("delivery.apartment", "Квартира")}
                    minLength="1"
                    maxLength="120"
                    required
                  />
                  <span
                    className={`${
                      errors.flat ? "login__error" : "login__error_hidden"
                    }`}
                  >
                    {t("delivery.field_required", "Поле обязательно для ввода")}
                  </span>
                </label>
              </div>
              <div className="delivery__description">
                <label className="delivery__label" htmlFor="floor">
                  {t("delivery.floor", "Этаж")}
                  <input
                    value={values.floor || ""}
                    onChange={handleChange}
                    id="floor"
                    className="delivery__input"
                    name="floor"
                    type="text"
                    placeholder={t("delivery.floor", "Этаж")}
                    minLength="1"
                    maxLength="120"
                  />
                  <span
                    className={`${
                      errors.floor ? "login__error" : "login__error_hidden"
                    }`}
                  >
                    {t("delivery.field_required", "Поле обязательно для ввода")}
                  </span>
                </label>
              </div>
              <div className="delivery__description">
                <label className="delivery__label" htmlFor="homephone">
                  {t("delivery.intercom", "Домофон")}
                  <input
                    value={values.homephone || ""}
                    onChange={handleChange}
                    id="homephone"
                    className="delivery__input"
                    name="homephone"
                    type="text"
                    placeholder={t("delivery.intercom", "Домофон")}
                    minLength="1"
                    maxLength="120"
                    // required
                  />
                  <span
                    className={`${
                      errors.homephone ? "login__error" : "login__error_hidden"
                    }`}
                  >
                    {t("delivery.field_required", "Поле обязательно для ввода")}
                  </span>
                </label>
              </div>
            </>
          )}
          <div className="delivery__description delivery__description-count">
            {t("delivery.number_of_utensils", "Количество приборов")}
            <button
              onClick={handleDelete}
              aria-label="Минус"
              type="button"
              className="delivery__btn-product_delete app__button-opacity"
            ></button>
            <span className="delivery__product-count">{persons_qty}</span>
            <button
              onClick={handleAdd}
              aria-label="Плюс"
              type="button"
              className="delivery__btn-product_add app__button-opacity"
            ></button>
          </div>
          <div className="delivery__description">
            <label className="delivery__label">
              {t("delivery.order_comment", "Комментарий к заказу")}
              <textarea
                value={values.comment || ""}
                onChange={handleChange}
                name="comment"
                maxLength="1500"
                className="delivery__textarea"
                placeholder={t(
                  "delivery.write_your_order_comments",
                  "Напишите свои комментарии к Вашему заказу"
                )}
              />
            </label>
          </div>

          <DatePicker
            setValues={setValues}
            handleChange={handleChange}
            values={values}
            setSelectedMonth={setSelectedMonth}
            selectedMonth={selectedMonth}
            workingHours={{
              startHour: timeDelivery[0]?.substring(0, 2),
              endHour: timeDelivery[1]?.substring(0, 2),
            }}
            selectedCity={selectedCity}
          />

          {errors.server && (
            <p className="login__error">
              {t(
                "errors.other_errors",
                "Кажется произошла ошибка. Если она повториться, перезвоните нам"
              )}
            </p>
          )}

          <h3
            className={
              values.payment_type
                ? "payment_choose"
                : "payment_choose--red payment_choose"
            }
          >
            {t("pickup.choose_payment_method", "Выберите способ оплаты")}
          </h3>
          <div className="payment__area">
            <div className="payment__area-box">
              <div className="delivery__description">
                {isDiscount && language === "ru" && (
                  <Tag text={"-10%*"} style={{ top: "-10px", left: "-30px" }} />
                )}
                <input
                  value="cash"
                  onChange={handleChange}
                  id="cash"
                  className="payment__input"
                  name="payment_type"
                  type="radio"
                  checked={values.payment_type === "cash" ? true : false}
                  required
                />
                <label className="payment__btn-cash" htmlFor="cash"></label>
              </div>
              <p className="payment__btn-text_cash">
                {t("pickup.payment_cash", "Наличными курьеру")}
              </p>
            </div>
            <div className="payment__area-box">
              <div className="delivery__description">
                <input
                  value="card_on_delivery"
                  onChange={handleChange}
                  id="card_on_delivery"
                  className="payment__input"
                  name="payment_type"
                  type="radio"
                  checked={
                    values.payment_type === "card_on_delivery" ? true : false
                  }
                  required
                />
                <label
                  className="payment__btn-card"
                  htmlFor="card_on_delivery"
                ></label>
              </div>
              <p className="payment__btn-text_card">
                {t("pickup.payment_card", "Картой курьеру")}
              </p>
            </div>
          </div>
          {isDiscount && language === "ru" && (
            <p className="discount">*При оплате наличными скидка 10%</p>
          )}

          <Promocode
            globalPromo={promoCode}
            handleSubmitPromo={checkPromo}
            handlePromoChange={handlePromoChange}
          ></Promocode>

          <button
            onClick={handleSubmit}
            className={`delivery__btn ${
              !isFullAddress || !isValid
                ? "delivery__btn-save_disable"
                : "app__button-opacity"
            }  ${loading && "button--loading"}`}
            disabled={!isFullAddress || !isValid}
            type="button"
            aria-label="Далее"
          >
            {!loading && <> {t("delivery.continue", "Далее")}</>}
          </button>
        </form>
        {errors.orderdishes && (
          <DishError
            language={language}
            dishes={errors.orderdishes}
            cartData={cartData}
          ></DishError>
        )}
        {isWrongTime && (
          <PopupRegistration
            setIsPopup={setIsWrongTime}
            message={`${t(
              "delivery.wrong_time",
              "Извините, сейчас мы закрыты, но вы можете оформить предварительный заказ, выбрав точное время выдачи заказа "
            )} ${timeDelivery[0] ? timeDelivery[0] : "" } - ${timeDelivery[1] ? timeDelivery[1] : ""}`}
          />
        )}
      </div>
    </>
  );
}

export default Delivery;
