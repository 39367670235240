import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid2 } from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./CategoryMenu.css";

function CategoryMenuSlider({ handleBurgerMenu, language, dishes }) {
  const { t } = useTranslation();
  const location = useLocation();

  // Функция для генерации списка уникальных категорий только для роллов и футомаки
  const uniqueCategories = Array.from(
    new Set(
      dishes.flatMap((dish) =>
        dish.category
          // .filter(cat => cat.slug === 'rolls' || cat.slug === 'futomaki')
          .map((cat) => cat.slug)
      )
    )
  );

  // Функция для получения названия категории в зависимости от языка
  const getCategoryName = (slug) => {
    const category = dishes
      .flatMap((dish) => dish.category)
      .find((cat) => cat.slug === slug);
    return category
      ? category.translations[language]?.name ||
          category.translations["en"].name
      : slug;
  };

  const carouselRef = React.useRef(null);

  React.useEffect(() => {
    if (carouselRef.current) {
      const path = location.pathname.slice(1, location.pathname.length);
      const index = uniqueCategories.indexOf(path);
      carouselRef.current.goToSlide(index > -1 ? index : 0); 
    }
  }, []);

  return (
    <Grid2
      container
      className="burger-category__grid"
      spacing={{ xs: 1, md: 0 }}
    >
      <Grid2 size={{ xs: 12, md: 1 }}>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "burger-category__link-active app__text-opacity burger-category__link-first"
              : "burger-category__link app__text-opacity burger-category__link-first"
          }
          to="/"
        >
          {t("category-menu.all", "Все меню")}
        </NavLink>
      </Grid2>

      <Grid2 size={{ xs: 12, md: 11 }}>
        <Carousel
          additionalTransfrom={0}
          ref={carouselRef}
          arrows
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          autoPlaySpeed={1000}
          centerMode={false}
          containerClass="burger-category--slider"
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass="burger-category__link--slider"
          keyBoardControl
          minimumTouchDrag={80}
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 6,
              partialVisibilityGutter: 10,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 4,
              partialVisibilityGutter: 10,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 4,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {uniqueCategories.map((slug) => (
            <NavLink
              key={slug}
              className={({ isActive }) =>
                isActive
                  ? "burger-category__link-active app__text-opacity"
                  : "burger-category__link app__text-opacity"
              }
              to={`/${slug}`}
            >
              {getCategoryName(slug)}
            </NavLink>
          ))}
        </Carousel>
      </Grid2>
    </Grid2>
  );
}

export default CategoryMenuSlider;

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <button className="menu-arrow menu-arrow--right" onClick={() => onClick()}>
      <ArrowForwardIosIcon />
    </button>
  );
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <button className="menu-arrow menu-arrow--left" onClick={() => onClick()}>
      <ArrowBackIosNewIcon />
    </button>
  );
};
