import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useFormValidation from "../../utils/FormValidation";
import logo from "../../images/logo.png";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import "./Login.css";
import Preloader from "../Preloader/Preloader";

function Login({language}) {
  const { t } = useTranslation();
  const { values, isValid, errors, handleChange, formRef } =
    useFormValidation();
  const { handleLogin, isPreloader } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleLogin(values.email, values.password)
      .then((res) => console.log(res))
      .catch((err) => {
        err
          ? setErrorMessage("errors.incorrect_email_or_password")
          : setErrorMessage("errors.error_during_login");
      });
  };

  useEffect(() => {
    if (errorMessage) {
      (!values.password || !values.email) && setErrorMessage(false);
    }
  }, [errorMessage, values.password, values.email]);

  return (
    <div className="login">
      <Link to="/">
        <img
          className="login__logo app__button-opacity"
          src={logo}
          alt="Логотип сайта"
        />
      </Link>
      <h2 className="login__text">
        {t("registr.glad_to_see_you", "Рады видеть!")}
      </h2>
      {isPreloader && (
        <div className="preloader-abs">
          <Preloader isPreloader={isPreloader}></Preloader>
        </div>
      )}
      <form
        ref={formRef}
        className={isPreloader ? "login__form form--grey" : "login__form"}
        onSubmit={handleSubmit}
        noValidate
      >
        <label className="login__label" htmlFor="email">
          E-mail
          <input
            value={values.email || ""}
            onChange={handleChange}
            id="email"
            className="login__input"
            name="email"
            type="email"
            minLength="6"
            maxLength="254"
            required
            pattern="^(http(s){0,1}:\/\/.){0,1}[\-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([\-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)$"
          />
          <span
            className={`${
              errors.email ? "login__error" : "login__error_hidden"
            }`}
          >
            {t(
              "errors.enter_valid_email",
              "Введите валидную почту(name@mail.com)"
            )}
          </span>
        </label>
        <label className="login__label" htmlFor="password">
          {t("registr.password", "Пароль")}
          <input
            value={values.password || ""}
            onChange={handleChange}
            id="password"
            className="login__input"
            name="password"
            type="password"
            minLength="8"
            maxLength="128"
            pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,100}$"
            required
          />
          <span
            className={`${
              errors.password ? "login__error" : "login__error_hidden"
            }`}
          >
            {t(
              "errors.enter_password_of_min_8_chars",
              "Пароль должен состоять из цифр и латинских букв, длина не менее 8 символов"
            )}
          </span>
        </label>
        <p className="register__error-text">
          {errorMessage && t(errorMessage)}
        </p>
        <button
          disabled={!isValid || isPreloader}
          className={`login__submit-button ${
            (!isValid || isPreloader) ? "login__submit-button_disable" : "app__button-opacity"
          }`}
          type="submit"
          aria-label="Войти"
        >
          {t("registr.login", "Войти")}
        </button>
      </form>
      <div className="login__signin-container">
        <Link to="/registration" className="login__signin app__text-opacity">
          {t("registr.not_registered_yet", "Еще не зарегистрированы? ")}
          <p className="login__signin_red">
            {t("registr.register_account", "Регистрация")}
          </p>
        </Link>
        <Link to="/reset-password" className="login__signin app__text-opacity">
          <p className="login__signin_red">
            {t("registr.forgot_pass", "Забыли пароль?")}
          </p>
        </Link>
      </div>
    </div>
  );
}

export default Login;
