import React from "react";
import "./Loader.css";

function Loader({ text = "Wait please..." }) {
  return (
    <div className="loader__container">
      <div className="loader">
        <div className="loader__inner"></div>
      </div>
      {text && <p className="loader__text">{text}</p>}
    </div>
  );
}

export default Loader;
