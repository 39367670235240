import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import "./index.css";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import "./utils/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Helmet>
      <title>Yume Sushi</title>
      <meta
        name="description"
        content="Yume Sushi - dostava sušija u Srbiji. Beograd."
      />
      <meta
        name="keywords"
        content="dostava sušija Beograd, суши Белград, sushi Beograd, Yume Sushi"
      />
       <meta property="og:title" content="Yume Sushi" />
      <meta
        property="og:description"
        content="Yume Sushi - dostava sušija u Srbiji. Beograd."
      />
      <meta
        property="og:image"
        content="https://yumesushi.rs/static/media/logo.4ef909a58c527e6c6acf.png"
      />
      <meta property="og:url" content="https://yumesushi.rs/" />
      <meta
        name="twitter:description"
        content="Yume Sushi - dostava sušija u Srbiji. Beograd."
      />
      <meta
        name="twitter:image"
        content="https://yumesushi.rs/static/media/logo.4ef909a58c527e6c6acf.png"
      />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
