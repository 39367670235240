import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

function MapDelivery({ location, onDtagEnd }) {
  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "400px",
        maxWidth: "700px",
      }}
      center={location}
      zoom={15}
    >
      <Marker
        // key={store.id}
        position={location}
        draggable={true}
        onDragEnd={(e) => {
          onDtagEnd(e);
        }}
      />
    </GoogleMap>
  );
}

export default MapDelivery;
