import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getMonthNames,
  isAsSoonAsPossible,
  generateDateOptions,
  generateTimeOptions,
} from "../../../utils/DateUtils/dateUtils";
import { use } from "i18next";

const DatePicker = ({
  setValues,
  handleChange,
  values,
  setSelectedMonth,
  selectedMonth,
  workingHours,
  selectedCity
}) => {
  const { t } = useTranslation();
  const [timeOptions, setTimeOptions] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);

  // Функция для вывода дня и месяца
  const MONTH_NAMES = getMonthNames(t);

  const getDefaultTime = () => {
    let selectedDate;
    if (!isAsSoonAsPossible(selectedMonth)) {
      const [day, monthName] = selectedMonth.split(" ");
      const monthNumber = MONTH_NAMES.indexOf(monthName);
      const year = new Date().getFullYear(); // или берем год из выбранной даты
      selectedDate = new Date(year, monthNumber, parseInt(day, 10));
    } else {
      selectedDate = new Date(); // если "Как можно быстрее", то считаем что дата – сегодня
    }
    setTimeOptions(generateTimeOptions(selectedDate, workingHours));
  };

  useEffect(() => {
    setSelectedMonth(dateOptions[0]);
    setDateOptions(generateDateOptions(t));
    getDefaultTime();
  }, []);

  useEffect(() => {
    getDefaultTime();
  }, [selectedCity]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    // преобразуем `selectedMonth` в дату для передачи её в `generateTimeOptions`
    if (!isAsSoonAsPossible(event.target.value)) {
      const [day, monthName] = event.target.value.split(" ");
      const monthNumber = MONTH_NAMES.indexOf(monthName);
      const year = new Date().getFullYear(); // или берем год из выбранной даты
      const selectedDate = new Date(year, monthNumber, parseInt(day, 10));
      const newTimeOptions = generateTimeOptions(selectedDate, workingHours);
      setTimeOptions(newTimeOptions);
      // обновляем состояние времени используя новую выбранную дату
      setValues((v) => ({
        ...v,
        delivery_time: newTimeOptions[0] || "",
      }));
    }
  };

  return (
    <div className="delivery__container delivery__container-data">
      {t("delivery.specify_date_time", "Укажите дату и время")}
      <div className="delivery__container-time">
        <select
          className="delivery__select"
          id="month"
          name="selectedMonth"
          onChange={(event) => {
            handleChange(event);
            handleMonthChange(event);
          }}
          value={selectedMonth}
        >
          {dateOptions.map((date, index) => (
            <option key={index} value={date} className="delivery__select-month">
              {date}
            </option>
          ))}
        </select>
        {!isAsSoonAsPossible(selectedMonth) && (
          <select
            className="delivery__select"
            id="time"
            name="delivery_time"
            onChange={handleChange}
            value={values.delivery_time || "11:00"}
          >
            {timeOptions.map((time, index) => (
              <option key={index} value={time}>
                {time}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};

export default DatePicker;
