import React, { useState, useEffect } from "react";
import DishesCardList from "../DishesCardList/DishesCardList";
import CategoryMenuSlider from "../CategoryMenu/CategoryMenuSlider";
import Preloader from "../Preloader/Preloader";
import {
  LAPTOP,
  PLANE_TABLE,
  MOBILE,
  SHOW_DISH_LAPTOP,
  SHOW_DISH_PLANE_TABLE,
  SHOW_DISH_MOBILE,
} from "../../utils/consts";
import More from "../More/More";
import "./Dishes.css";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useTranslation } from "react-i18next";

function Dishes({
  dishes,
  onDishClick,
  handleBurgerMenu,
  isPreloader,
  language,
  onAddToCart,
  cartData,
  isMenu,
}) {
  // сколько карточек показывать при определенном размере окна
  const [isSize, setSize] = useState(window.innerWidth);
  const [moviesRow, setMoviesRow] = useState(SHOW_DISH_LAPTOP);
  const [moreButton, setMoreButton] = useState(false);
  const { t } = useTranslation();

  function handleSizeWindow() {
    if (LAPTOP <= isSize) {
      setMoviesRow(SHOW_DISH_LAPTOP);
    } else if (PLANE_TABLE <= isSize) {
      setMoviesRow(SHOW_DISH_PLANE_TABLE);
    } else if (MOBILE <= isSize) {
      setMoviesRow(SHOW_DISH_MOBILE);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleMoreButton();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleSizeWindow();
  }, [isSize]);

  function handleResize() {
    setSize(window.innerWidth);
  }

  useEffect(() => {
    if (dishes.length < moviesRow.total) {
      setMoreButton(false);
    } else if (dishes.length >= moviesRow.total) {
      setMoreButton(true);
    }
  }, [dishes.length, moviesRow.total]);

  function handleMoreButton() {
    setMoviesRow({
      ...moviesRow,
      total: moviesRow.total + moviesRow.plus,
    });
  }

  return (
    <>
      <CategoryMenuSlider
        handleBurgerMenu={handleBurgerMenu}
        language={language}
        dishes={dishes}
      />
      {isPreloader ? (
        <Preloader />
      ) : isMenu ? (
        <>
          <DishesCardList
            onDishClick={onDishClick}
            dishes={dishes.slice(0, moviesRow.total)}
            language={language}
            onAddToCart={onAddToCart}
            cartData={cartData}
          />
          <More moreButton={moreButton} handleMoreButton={handleMoreButton} />
        </>
      ) : (
        <div className="dishes__notFound">
          <h1 className="dishes__title">
            {t(
              "errors.not_found_menu",
              "Меню этого ресторана пока не доступно 😔"
            )}
          </h1>

          <MenuBookIcon sx={{ fontSize: 100, color: "#ff3055" }} />
        </div>
      )}
    </>
  );
}

export default Dishes;
