const checkForDigits = (str) => {
  return /\d/.test(str);
};

function prepareAddress(
  recipient_address,
  PrivateHome,
  home,
  flat,
  floor,
  homephone
) {
  let addressWithHome = recipient_address.split(",", 1);
  if (home) {
    addressWithHome = addressWithHome + `home ${home}`;
  } else if (PrivateHome) {
    addressWithHome = addressWithHome + ` private home ${PrivateHome}`;
  }

  let comment = "";
  flat && (comment = comment + `flat: ${flat}, `);
  floor && (comment = comment + `floor: ${floor}, `);
  homephone && (comment = comment + `interfon: ${homephone}, `);
  let isHome = false;
  if (checkForDigits(addressWithHome)) {
    isHome = true;
  }
  return {
    addressWithHome: addressWithHome,
    commentWithAddress: comment,
    isHome: isHome,
  };
}

const splitComment = (fullComment) => {
  if (fullComment) {
    let flat = "";
    let floor = "";
    let homephone = "";
    let comment = "";
  
    fullComment.split(",").forEach((item) => {
      if (item.includes("flat")) {
        flat = item.replace(/flat:/, "").trim();;
      } else if (item.includes("floor")) {
        floor = item.replace(/floor:/, "").trim();;
      } else if (item.includes("comment")) { 
        comment = item.replace(/comment from user:/, "").trim();;
      } else if (item.includes("interfon")) {
        homephone = item.replace(/interfon:/, "").trim();;
      }
    })
   
    return { flat: flat, floor: floor, homephone: homephone, comment: comment };
  }
  return { flat: "", floor: "", homephone: "", comment: "" };
}

export { splitComment, prepareAddress };
